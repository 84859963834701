import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import schoolLogo from "./images/logo-small.jpg";
import { FaCaretDown, FaBars, FaTimes } from "react-icons/fa";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import UserLogin from "./modals/userLogin";
import { useStateContext } from "../contexts/contextProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import axiosClient from "../axiosClient";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function NavBar() {
  const [click, setClick] = useState(false);
  const [user, setUser] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const { setToken } = useStateContext();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUser = async () => {
      const accessToken = localStorage.getItem("ACCESS_TOKEN");
      if (accessToken) {
        try {
          const response = await axiosClient.get(`/user`);
          setUser(response.data);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUser();
  }, []);

  const toggleLogin = () => {
    setShowLogin(!showLogin);
  };

  const handleLogout = async (event) => {
    event.preventDefault();

    await axiosClient.get("/logout").then((response) => {
      setUser(null);
      // @ts-ignore
      setToken(null);
      navigate("/home");

      // Clear cookies except for specific ones
      const allCookies = Cookies.get(); // Get all cookies as an object

      const exemptCookies = [
        "laravel_session",
        "entriesPerPageUpcoming_3",
        "entriesPerPageRequests_3",
        "entriesPerPageRegistered_3",
      ];

      Object.keys(allCookies).forEach((cookieName) => {
        if (!exemptCookies.includes(cookieName)) {
          Cookies.remove(cookieName, { path: "/" }); // Remove the cookie
        }
      });

      // Optionally, reload the page to ensure all cookies are cleared
      window.location.reload();
    });
  };
  const handleClick = () => setClick(!click);

  return (
    <nav className="navbar">
      <div className="nav-container">
        <NavLink to="/" className="nav-logo">
          <img
            alt="Seattle Voctech School"
            className="head-logo"
            src={schoolLogo}
          />
        </NavLink>
        <div className="menu-icon" onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <NavLink to="/home" className="nav-links" onClick={handleClick}>
              HOME
            </NavLink>
          </li>
          <li className="nav-item">
            <Dropdown title="ACADEMY" link="/academy">
              <NavLink
                to="/academy"
                className="nav-links"
                onClick={handleClick}
              >
                Highschool Core Curriculum
              </NavLink>
              <NavLink
                to="https://seattlevt.org/achance/#/"
                className="nav-links"
                onClick={handleClick}
                target="_blank"
              >
                Short Course or Summer Program
              </NavLink>
              <NavLink
                to="/tutorial-credit"
                className="nav-links"
                onClick={handleClick}
              >
                Tutoring and Credit Course
              </NavLink>
            </Dropdown>
          </li>
          <li className="nav-item">
            <Dropdown title="COLLEGE CAP" link="/college-cap">
              <NavLink
                to="/college-cap"
                className="nav-links"
                onClick={handleClick}
              >
                Music Direction
              </NavLink>
              <NavLink
                to="/interschool"
                className="nav-links"
                onClick={handleClick}
              >
                Interschool Cooperation
              </NavLink>
            </Dropdown>
          </li>
          <li className="nav-item">
            <Dropdown title="STEAM" link="/steam">
              {isMobile && (
                <NavLink
                  to="/steam"
                  className="nav-links"
                  onClick={handleClick}
                >
                  STEAM
                </NavLink>
              )}
              <NavLink
                to="http://www.2dacademy.com/"
                className="nav-links"
                onClick={handleClick}
                target="_blank"
              >
                2D Animation & Award
              </NavLink>
              <NavLink
                to="https://seattlela.org/"
                className="nav-links"
                onClick={handleClick}
                target="_blank"
              >
                Youth Speaking & Debate
              </NavLink>
              <NavLink
                to="/robotics"
                className="nav-links"
                onClick={handleClick}
              >
                WASABI Competitive Robotics
              </NavLink>
              <NavLink
                to="/drones-program"
                className="nav-links"
                onClick={handleClick}
              >
                Drones Program
              </NavLink>
            </Dropdown>
          </li>

          <li className="nav-item">
            <Dropdown title="STUDENT" link="/application-process">
              <NavLink
                to="/application-process"
                className="nav-links"
                onClick={handleClick}
              >
                Application Process
              </NavLink>
              <NavLink
                to="/college-case"
                className="nav-links"
                onClick={handleClick}
              >
                College Cases
              </NavLink>
              <NavLink
                to="/esl-case"
                className="nav-links"
                onClick={handleClick}
              >
                ESL Cases
              </NavLink>
              <NavLink
                to="/contact-us"
                className="nav-links"
                onClick={handleClick}
              >
                Contact Us
              </NavLink>
            </Dropdown>
          </li>
          <li className="nav-item">
            <Dropdown title="ABOUT US" link="/welcome-letter">
              <NavLink
                to="/welcome-letter"
                className="nav-links"
                onClick={handleClick}
              >
                Welcome Letter
              </NavLink>
              <NavLink
                to="/mission-statement"
                className="nav-links"
                onClick={handleClick}
              >
                Mission Statement
              </NavLink>
              <NavLink
                to="/staff-team"
                className="nav-links"
                onClick={handleClick}
              >
                Staff Team
              </NavLink>
            </Dropdown>
          </li>
          <li className={user ? "nav-item-loggedin" : "nav-item-login"}>
            {user ? (
              <Dropdown
                title={`Hello, ${user.preferredName || user.firstName}`}
                link={null}
              >
                <NavLink className="nav-links" to="/user-home">
                  User Home
                </NavLink>
                <NavLink className="nav-links" onClick={handleLogout} to="#">
                  Logout
                </NavLink>
              </Dropdown>
            ) : (
              <NavLink
                to="#"
                className="nav-links login-icon"
                onClick={toggleLogin}
              >
                <AccountCircleOutlinedIcon style={{ fontSize: "3vmin" }} />
              </NavLink>
            )}
            {showLogin && <UserLogin setShowModal={setShowLogin} />}
          </li>
        </ul>
      </div>
    </nav>
  );
}

function Dropdown({ title, children, link }) {
  const [dropdown, setDropdown] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    document.querySelectorAll(".dropdown-content").forEach((item) => {
      item.classList.remove("show");
    });
    setDropdown(!dropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    if (isMobile) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile]);

  return (
    <div className="dropdown" ref={dropdownRef}>
      {isMobile ? (
        <div className=" mobile-nav-links" onClick={toggleDropdown}>
          {title}
          <FaCaretDown style={{ fontSize: "1vmin" }} />
        </div>
      ) : (
        <NavLink to={link} className="nav-links" onClick={toggleDropdown}>
          {title}
          <FaCaretDown style={{ fontSize: "1vmin" }} />
        </NavLink>
      )}
      {isMobile ? (
        dropdown && <div className="dropdown-content show">{children}</div>
      ) : (
        <div
          className={dropdown ? "dropdown-content show" : "dropdown-content"}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default NavBar;
