import "./applicationProcess.css";

const ApplicationProcess = () => {
  return (
    <div className="application-process-page">
      <div className="application-process-container">
        <h2>Application Process</h2>
        <ol>
          <li>
            Phase one
            <ol>
              <li>
                You will:
                <ol type="i">
                  <li>
                    Download{" "}
                    <a
                      href="./ApplicationPackage_en_2023-2024.pdf"
                      target="applicationforms"
                      style={{ color: "red", textDecoration: "underline" }}
                    >
                      the application package
                    </a>{" "}
                    in PDF format.
                  </li>
                  <li>
                    Fill out and submit the Application forms and Bank
                    Verification form. (email to contact@SeattleVT.org)
                  </li>
                  <li>
                    Have official transcripts sent from all schools you have
                    attended in the last two years.
                  </li>
                  <li>Pay the $100 (non-refundable) application fee.</li>
                  <li>
                    Schedule and participate in a video interview with one of
                    our staffs.
                  </li>
                </ol>
              </li>
              <li>
                We will:{" "}
                <ol type="i">
                  <li>
                    Acknowledge receipt of your completed Application and assign
                    an account number.
                  </li>
                  <li>
                    Acknowledge receipt of your completed Bank Verification,
                    Transcripts, and Fee.
                  </li>
                  <li>Schedule the video interview.</li>
                  <li>
                    Evaluate your application and make a decision within 14 days
                    of receipt of the completed materials.
                  </li>
                  <li>Inform you of our decision about admissions.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Phase two{" "}
            <ol type="a">
              <li>
                If you are admitted, you will:{" "}
                <ol type="i">
                  <li>
                    Pay tuition (two payment plans available, see Payment Plans)
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Phase three{" "}
            <ol type="a">
              <li>
                We will:{" "}
                <ol type="i">
                  <li>
                    Send you your I-20 documents, and an visa application
                    checklist and advice for applying for your visa, within 14
                    days.
                  </li>
                </ol>
              </li>
              <li>
                You will:{" "}
                <ol type="i">
                  <li>
                    Assemble all required documents and apply for the visa at
                    the nearest US Embassy.
                  </li>
                  <li>
                    Inform us of the success or failure of your application.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <br />
        <br />
        <h2 id="apply">Submit ALL forms (in pdf format) to:</h2>
        <div
          className="paragraph"
          style={{ textAlign: "left", display: "block" }}
        >
          <span>
            contact@SeattleVT.org
            <br />
            Subject: International Admissions{" "}
          </span>
        </div>
        <h2 id="apply">Questions?</h2>
        <div
          className="paragraph"
          style={{
            textAlign: "left",
            display: "block",
            marginBottom: "50px",
          }}
        >
          <span>
            If you need assistance with any part of the application process,
            please contact the Admissions Coordinator:{" "}
            <a href="mailto:contact@Seattlevt.org">contact@SeattleVT.org</a>{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ApplicationProcess;
