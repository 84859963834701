import pic1 from "./images/Picture1.png";
import pic2 from "./images/Picture2.png";
import "./interschoolCoop.css";
const InterschoolCoop = () => {
  return (
    <div className="interschool-page">
      <div className="interschool-container">
        <div className="interschool-content">
          <h2>Interschool Cooperation</h2>
          <br />
          <br />
          <p className="interschool-paragragh">
            <b>
              <span>
                {" "}
                SVT has reached an agreement in May 2024 with Jinan Deya
                Senior High School and Shandong Hualianguangcheng Education
                Consulting Co. Ltd on the CAP joint program of “US-Study Express
                Class”!
              </span>
            </b>
          </p>
          <p className="interschool-imgs">
            <img src={pic1} alt="pic1" />
            <img src={pic2} alt="pic2" />
          </p>
          <table>
            <tbody>
              <tr>
                <td>
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InterschoolCoop;
