/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
import { React } from "react";
import "./academy.css";

const Academy = () => {
  function SideNav() {
    return (
      <div className="academy-side-nav">
        <a href="">Core Courses</a>
        <a href="#baseedu-side2">AP Courses</a>
        <a href="#baseedu-side3">Literacy Advantage</a>
        <a href="#baseedu-side4">Honor Courses</a>
        <a href="#baseedu-side5">Foundations</a>
      </div>
    );
  }

  return (
    <div className="academy-page">
      <div className="academy-container">
        <SideNav className="academy-side-nav" />
        <div className="academicmain">
          <h2>Core Courses</h2>
          <ul className="three-columns">
            <b>Math</b>
            <li>Algebra(I＆II)</li>
            <li>Geometry</li>
            <li>Mathematics(I, II &amp; III)</li>
            <li>Precalculus</li>
            <li>Financial Literature</li>
            <li>Liberal Arts Math</li>
            <li>Mathematics of Personal Finance</li>
            <li>Probability &amp; Statistics</li>
            <br />
            <b>English</b>
            <li>English(9, 10, 11 &amp; 12)</li>
            <li>Introduction to Literature &amp; Composition</li>
            <li>Critical Reading &amp; Effective Writing</li>
            <li>American Literature</li>
            <li>British &amp; World Literature</li>
            <li>Creative Writing</li>
            <li>Media Literacy</li>
            <li>Reading Skills &amp; Strategies</li>
            <li>Writing Skills &amp; Strategies</li>
            <br />
            <b>Languages</b>
            <li>French(I &amp; II)</li>
            <li>Spanish(I, II &amp; III)</li>
            <li>Mandarin Chinese(I &amp; II)</li>
            <li>Latin(I &amp; II)</li>
            <li>German(I &amp; II)</li>
            <br />
            <b>Soc. Studies</b>
            <li>Geography &amp; World Cultures</li>
            <li>World History</li>
            <li>World History to Renaissance</li>
            <li>U.S. History</li>
            <li>U.S. History to Civil War</li>
            <li>U.S. History since Civil War</li>
            <li>U.S. Government and Politics</li>
            <li>U.S. &amp; Global Economics</li>
            <li>Multicultural Economics</li>
            <li>Sociology</li>
            <li>Contemporary World Problems</li>
            <br />
            <b>Science</b>
            <li>Earth Science</li>
            <li>Physical Science</li>
            <li>Biology</li>
            <li>Chemistry</li>
            <li>Physics</li>
            <li>Psychology</li>
            <br />
            <b>Career and Technical Education</b>
            <li>Business Application</li>
            <li>Introduction to Health Science</li>
            <li>Intermediate Health Science</li>
            <li>Principle of Information Technology</li>
            <li>Information Technology Applications</li>
            <li>Principles of Business &amp; Marketing</li>
            <br />
            <b>Other</b>
            <li>College &amp; Career Preparation(I &amp; II)</li>
            <li>Art Appreciation</li>
            <li>Music Appreciation</li>
            <li>Physical Education</li>
            <li>Skills for Health</li>
          </ul>

          <h2 id="baseedu-side2" />
          <br />
          <br />
          <br />
          <br />

          <h2>AP Courses</h2>
          <ul className="three-columns">
            <li>Art History</li>
            <li>Biology</li>
            <li>Calculus (AB &amp; BC)</li>
            <li>Chemistry</li>
            <li>Chinese Language and Culture</li>
            <li>Comparative Government &amp; Politics</li>
            <li>Computer Science A</li>
            <li>Computer Science Principles</li>
            <li>English Language &amp; Composition</li>
            <li>English Literature &amp; Composition</li>
            <li>Environmental Science</li>
            <li>European History</li>
            <li>Human Geography</li>
            <li>Japanese Language and Culture</li>
            <li>Macroeconomics</li>
            <li>Microeconomics</li>
            <li>Music Theory</li>
            <li>Physics 1: Algebra-based</li>
            <li>Physics 2: Algebra-based</li>
            <li>Physics C: Mechanics</li>
            <li>Physics C: Electricity and Magnetism</li>
            <li>Psychology</li>
            <li>Spanish Language and Culture</li>
            <li>Spanish Literature and Culture</li>
            <li>Statistics</li>
            <li>U.S. History</li>
            <li>U.S. Government &amp; Politics</li>
            <li>World History: Modern</li>
          </ul>

          <h2 id="baseedu-side3" />
          <br />
          <br />
          <br />
          <br />
          <h2>Literacy Advantage</h2>

          <ul className="three-columns">
            <b>Math</b>
            <li>algebra I &amp; II</li>
            <li>Geometry</li>
            <br />
            <b>Science</b>
            <li>Physical Science</li>
            <li>Biology</li>
            <li>Chemistry</li>
            <br />
            <b>English</b>
            <li>Introduction to Literature &amp; Composition</li>
            <li>Critical Reading &amp; Effective Writing</li>
            <li>American Literature</li>
            <br />
            <b>Social Studies</b>
            <li>U.S. History to Civil War</li>
            <li>U.S. History since Civil War</li>
            <li>U.S. Government and Politics</li>
          </ul>

          <h2 id="baseedu-side4" />
          <br />
          <br />
          <br />
          <br />
          <h2>Honor Courses</h2>

          <ul className="three-columns">
            <b>Math</b>
            <li>Algebra(I＆II)</li>
            <li>Geometry</li>
            <li>Precalculus</li>
            <li>Advanced Calculus</li>
            <br />
            <b>World Languages</b>
            <li>French(I＆II)</li>
            <li>Spanish(I＆II)</li>
            <br />
            <b>Science</b>
            <li>Earth Science</li>
            <li>Physical Science</li>
            <li>Biology</li>
            <li>Chemistry</li>
            <li>Physics</li>
            <br />
            <b>English</b>
            <li>Introduction to Literature &amp; Composition</li>
            <li>Critical Reading &amp; Effective Writing</li>
            <li>American Literature</li>
            <li>British &amp; World Literature</li>
            <br />
            <b>Social Studies</b>
            <li>Geography &amp; World Cultures</li>
            <li>World History</li>
            <li>World History to Renaissance</li>
            <li>U.S. History</li>
            <li>U.S. History to Civil War</li>
            <li>U.S. History since Civil War</li>
            <li>U.S. &amp; Global Economics</li>
            <li>U.S. Government and Politics</li>
          </ul>

          <h2 id="baseedu-side5" />
          <br />
          <br />
          <br />
          <br />
          <h2>Foundations</h2>

          <ul className="three-columns">
            <li>Math Foundations(I &amp; II)</li>
            <li>Science Foundations</li>
            <li>English Foundation(I &amp; II)</li>
          </ul>
          <div style={{ height: "50px" }}></div>
        </div>
      </div>
    </div>
  );
};

export default Academy;
