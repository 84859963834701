import React, { useState } from "react";
import "./contactUs.css";
import axios from "axios";
import { GlobalConstant } from "../GlobalConstant";
import axiosClient from "../axiosClient";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "United States",
    content: "",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };

    switch (name) {
      case "name":
        newErrors.name = value.trim() ? "" : "Name is required";
        break;
      case "email":
        newErrors.email = value.trim()
          ? /^\S+@\S+\.\S+$/.test(value)
            ? ""
            : "Invalid email address"
          : "Email is required";
        break;
      case "phone":
        newErrors.phone = value.trim() ? "" : "Phone is required";
        break;
      case "content":
        newErrors.content = value.trim() ? "" : "Content is required";
        break;
      default:
        break;
    }

    setErrors(newErrors);

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = Object.values(errors).every((error) => error === "");

    if (isValid) {
      try {
        await axiosClient.post(`/contactForms`, formData);
        setSubmitted(true);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      console.log("Form has errors, cannot submit");
    }
  };

  return (
    <div className="contact-page">
      <div className="contact-container">
        <br />
        <h3>Contact Us</h3>
        <br />
        {!submitted ? (
          <div className="contact-form-container">
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Name *</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your first name and last name ..."
                autoFocus
                required
              />
              {errors.name && (
                <span className="contact-form-error">{errors.name}</span>
              )}

              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your email address ..."
                required
              />
              {errors.email && (
                <span className="contact-form-error">{errors.email}</span>
              )}

              <label htmlFor="phone">Phone *</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Your phone number ..."
                required
              />
              {errors.phone && (
                <span className="contact-form-error">{errors.phone}</span>
              )}

              <label htmlFor="country">Country</label>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                <option value="China">China</option>
                <option value="United States">United States</option>
                <option value="Canada">Canada</option>
              </select>

              <label htmlFor="content">Content *</label>
              <textarea
                id="content"
                name="content"
                value={formData.content}
                onChange={handleChange}
                placeholder="Why you contact us ..."
                style={{ height: "25vmin" }}
                required
              ></textarea>
              {errors.content && (
                <span className="contact-form-error">{errors.content}</span>
              )}

              <input
                type="submit"
                value="Submit"
                disabled={
                  !formData.name ||
                  !formData.email ||
                  !formData.phone ||
                  !formData.content ||
                  Object.values(errors).some((error) => error !== "")
                }
              />
            </form>
          </div>
        ) : (
          <div
            className="thank-you-message"
            style={{ height: "80vh", textAlign: "center" }}
          >
            <br />
            <h4>Thank you for your message!</h4>
            <br />
          </div>
        )}
        <br />
        <br />
      </div>
    </div>
  );
};

export default ContactUs;
