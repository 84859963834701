import React, { useState, useEffect } from "react";
import axiosClient from "../../axiosClient";
import "./userEdit.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import AddComments from "./addComments";
import CreateEditClassModal from "./createEditClassModal";

function UserEdit({
  requestId,
  requestData,
  setShowModal,
  requestWidget,
  setRequestWidget,
  handleFetch,
  handleFetchRequest,
}) {
  const [request, setRequest] = useState(null);
  const [status, setStatus] = useState(""); // State to hold the status
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [showAssignClass, setShowAssignClass] = useState(false);
  const fetchRequestData = async () => {
    try {
      console.log(requestId);
      const response = await axiosClient.get(`/request/${requestId}`);
      const fetchedRequest = response.data;

      // if (requestData.svtRequestID === fetchedRequest.svtRequestID) {
      //   setRequest({ ...fetchedRequest, ...requestData });
      // }
      // else
      // {
      setRequest(fetchedRequest);
      // }
      setStatus(fetchedRequest.status); // Set the initial status
    } catch (error) {
      console.error("Error fetching request data:", error);
    }
  };
  useEffect(() => {
    fetchRequestData();
  }, [requestId, requestData]);

  const handleSave = async () => {
    try {
      const response = await axiosClient.post(`/adminEdit`, {
        requestID: request.svtRequestID,
        status: status, // Send the updated status
      });
      if (response.status === 200) {
        await handleFetchRequest();
        alert("Request updated successfully");
        // Optionally, you can refresh the request data or perform other actions here
      } else {
        alert("Failed to update request");
      }
    } catch (error) {
      console.error("Error updating request:", error);
      alert("Error updating request");
    }
  };

  const handleAssignClass = () => {
    // Implement the assign class functionality here
    setShowAssignClass(true);
  };
  console.log("Class Request: " + JSON.stringify(request));
  return (
    <div className="user-edit-popup">
      <div className="user-edit-popup-content">
        {request ? (
          <div className="user-edit-content">
            <button
              className="close-useredit-button"
              onClick={() => setShowModal(false)}
            >
              <ClearOutlinedIcon />
            </button>
            <h1>Edit Requests</h1>

            <div className="user-edit-form-group">
              <label>Created at:</label>
              <span>{new Date(request.created_at).toLocaleString()}</span>
            </div>
            <div className="user-edit-form-group">
              <label>Updated at:</label>
              <span>{new Date(request.updated_at).toLocaleString()}</span>
            </div>
            <div className="user-edit-form-group">
              <label>Status:</label>
              <div className="user-edit-form-group-inner">
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="open">open</option>
                  <option value="processing">processing</option>
                  <option value="solved">solved</option>
                  <option value="canceled">canceled</option>
                  <option value="approved"> approved</option>
                </select>
                <button onClick={handleSave}>Save</button>
              </div>
            </div>
            <div className="user-edit-form-group">
              <label>Requestor:</label>
              <span>{`${requestData.firstName} ${requestData.surname}`}</span>
            </div>
            <div className="user-edit-form-group">
              <label>Type:</label>
              <span>{request.type}</span>
            </div>
            <div className="user-edit-form-group">
              <label>Course name:</label>
              <span>{requestData.courseName}</span>
            </div>
            <div className="user-edit-form-group">
              <label>Class name:</label>
              <div className="user-edit-form-group-inner">
                <span>{requestData.courseClassName}</span>
                <button onClick={handleAssignClass}>Assign Class</button>
              </div>
            </div>
            <div className="user-edit-form-group">
              <label>Class purpose:</label>
              <span>{request.purpose}</span>
            </div>
            <div className="user-edit-form-group">
              <label>Comments:</label>
              <div className="user-edit-form-group-inner">
                <textarea value={request.comments} disabled={true}></textarea>
                <button onClick={() => setShowCommentsModal(true)}>
                  Update Comment
                </button>
              </div>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      {showCommentsModal && (
        <AddComments
          setShowCommentsModal={setShowCommentsModal}
          setRequest={setRequest}
          request={request}
          fetchRequest={() => {
            handleFetchRequest();
            fetchRequestData();
          }}
        />
      )}
      {showAssignClass && (
        <CreateEditClassModal
          setShowModal={setShowAssignClass}
          courseRequest={request}
          requestType={"request"}
          handleFetch={handleFetch}
        />
      )}
    </div>
  );
}

export default UserEdit;
