/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */

import "./steam.css";
import speaking from "./images/speaking.png";

const STEAM = () => {
  function SideNav() {
    return (
      <div className="steam-side-nav">
        <a href="">Competitive Robotics</a>
        <a href="#specialtyedu-side2">2D Animation</a>
        <a href="#specialtyedu-side3">Speaking & Debate</a>
      </div>
    );
  }

  return (
    <div className="steam-page">
      <div className="steam-container">
        <SideNav className="steam-side-nav" />
        <div className="steam-content">
          <h2 id="specialtyedu-side1">Competitive Robotics</h2>
          <ul className="two-columns">
            <li>Introduction to Autodesk Inventor</li>
            <li>
              Introduction to Robotics/Building Robots{" "}
              <ul>
                <li className="insidelist">Introduction to Engineering</li>
                <li className="insidelist">Introduction to Robotics</li>
                <li className="insidelist">
                  Speed, Power, Torque &amp; DC Motors
                </li>
                <li className="insidelist">Mechanical Power Transmission</li>
                <li className="insidelist">Drivetrain Design</li>
                <li className="insidelist">Lifting Mechanisms</li>
                <li className="insidelist">Systems Integration</li>
                <li className="insidelist">
                  Testing and the Iteration Process
                </li>
                <li className="insidelist">Object Manipulation</li>
              </ul>
            </li>
            <li>Aerial Robotics/Drones</li>
            <li>Computational Motion Planning</li>
            <li>Mobility</li>
            <li>Perception</li>
            <li>Estimation and Learning</li>
            <li>Capstone Project</li>
          </ul>

          <h2 id="specialtyedu-side2" />
          <br />
          <br />
          <br />

          <h2>2D Animation</h2>
          <ul className="two-columns">
            <b>1-year FOUNDATION IN ART &amp; ANIMATION</b>
            <li>Drawing for Animation</li>
            <li>Character Design</li>
            <li>Color Theory</li>
            <li>Perspective</li>
            <li>Basic Anatomy</li>
            <li>Concept Art &amp; Storyboarding</li>
            <li>The Core Principles of Animated Movement</li>
            <li>Traditional 2D Animation</li>
            <li>1-year Final Project</li>
          </ul>

          <h2 id="specialtyedu-side3" />
          <br />
          <br />
          <br />

          <h2>Speaking & Debate</h2>

          <img src={speaking} alt="speaking"></img>
          <br />
          <br />
          <div style={{ height: "50px" }}></div>
        </div>
      </div>
    </div>
  );
};

export default STEAM;
