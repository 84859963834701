// src/Footer.js
import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-column">
        <div className="footer-row">
          <p>Copyright © 2018 Seattle Voctech School.</p>
        </div>
        <div className="footer-row">
          <p>All Rights Reserved.</p>
        </div>
      </div>
      <div className="footer-column">
        <div className="footer-row">
          <p>US Hotline: (+1)425-457-1965</p>
        </div>
        <div className="footer-row">
          <p>
            E-mail:{" "}
            <a href="mailto:contact@seattlevt.org">contact@seattlevt.org</a>
          </p>
        </div>
        <div className="footer-row">
          <p>
            Address:{" "}
            <a
              href="https://maps.app.goo.gl/5uarHW94PvEpX8318"
              target="_blank"
              rel="noopener noreferrer"
            >
              15044 SE 44th Pl, Bellevue, WA 98006
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
