import React from "react";
import "./welcomeLetter.css";

const WelcomeLetter = () => {
  return (
    <div className="welcome-letter-page">
      <div className="welcome-letter-container">
        <tbody>
          <tr>
            <td align="center" style={{ width: "100%", padding: "30px" }}>
              <span
                style={{
                  fontFamily: "Arial, sans-serif",
                  fontSize: "4vmin",
                  color: "#555555",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                Welcome Letter{" "}
              </span>
            </td>
          </tr>
          <tr>
            <td align="center" style={{ width: "100%", padding: "30px" }}></td>
          </tr>
          <tr>
            <td style={{ width: "100%", padding: "0 15px" }}>
              <div style={{ textAlign: "left", display: "block" }}>
                <span
                  style={{
                    fontFamily: "Arial, sans-serif",
                    fontSize: "3vmin",
                    color: "#000",
                    fontWeight: "plain",
                    textDecoration: "none",
                  }}
                >
                  We are delighted to introduce you to Seattle Voctech School —
                  a fully accredited, U.S./Washington state diploma-granting,
                  private school for grades 6 –12. We help students learn in the
                  ways that are right for them, nurturing a joy for learning and
                  a passion for excellence in all of their pursuits.
                  <br />
                  <br />
                  The key is our individualized approach, which frees children
                  to reach their personal potential. Our students are liberated
                  from rigid schedules, classes that move too fast or too slow,
                  overcrowded classrooms, teaching to the test, and many other
                  factors that typically stand in the way of academic engagement
                  and success. Rather we use our award-winning digital
                  curriculum, developed by cognitive scientists and master
                  educators and tested on thousands of students to personalize
                  each student's program.
                  <br />
                  <br />
                  We don't try to be everything to everyone. We are, first and
                  foremost, an institution for passionate creators: Programmers,
                  designers，roboteers and young self-leaders. Our students love
                  learning by doing, self-directed learning and projects and
                  internships with real objectives and real outputs.
                  <br />
                  <br />
                  If you're looking for a school that focuses on your student's
                  passion for creation rather than an arbitrary slate of liberal
                  arts subjects, we invite you to learn more on these pages.
                  Join us and experience our unique teaching mechanism.
                  <br />
                  <br />
                  <br />
                  <br />
                  Sincerely,
                  <br />
                  <br />
                  SVT Board
                </span>
              </div>
            </td>
          </tr>
        </tbody>
        <br />
        <br />
      </div>
    </div>
  );
};

export default WelcomeLetter;
