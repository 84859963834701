import React, { useState, useEffect } from "react";
import "./classRequestFormModal.css";
import axiosClient from "../../axiosClient";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const ClassRequestFormModal = ({
  handleFetchRequest,
  showModal,
  setShowModal,
}) => {
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    courseName: "",
    courseID: "",
    departmentName: "",
    classPurpose: "tutorial",
    studentFirstName: "",
    studentLastName: "",
    gender: "",
    school: "",
    grade: "",
    email: "",
    phoneNumber: "",
  });
  const [courseNames, setCourseNames] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingCourses, setLoadingCourses] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const accessToken = localStorage.getItem("ACCESS_TOKEN");
      if (accessToken) {
        try {
          const response = await axiosClient.get(`/user`);
          setUser(response.data);
          setLoadingUser(false);
        } catch (error) {
          console.error("Error fetching user data:", error);
          setLoadingUser(false);
        }
      } else {
        setLoadingUser(false);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const fetchCourseNames = async () => {
      try {
        const response = await axiosClient.get(`/allcourses`);
        const sortedCourseNames = response.data.data.sort(
          (a, b) => a.sequenceNumber - b.sequenceNumber
        );
        setCourseNames(sortedCourseNames);
        setLoadingCourses(false);
      } catch (error) {
        console.error("Failed to fetch course names:", error);
        setLoadingCourses(false);
      }
    };

    fetchCourseNames();
  }, []);

  /**
   * Safely parse a JSON string and return the parsed object or an empty object.
   * Note: If JSON is invalid or empty, it will return an empty object.
   */
  const safeJsonParse = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return {};
    }
  };

  useEffect(() => {
    if (user) {
      // Parse the JSON fields and extract the grade
      const userFields = safeJsonParse(user.fields);
      const userGrade = userFields.grade || "";

      setFormData((prevFormData) => ({
        ...prevFormData,
        studentFirstName: user.firstName,
        studentLastName: user.surname,
        gender: user.gender,
        email: user.email,
        grade: userGrade,
      }));
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };

    switch (name) {
      case "departmentName":
        newErrors.departmentName = value.trim()
          ? ""
          : "Learning area is required";
        break;
      case "courseName":
        newErrors.courseName = value.trim() ? "" : "Course name is required";
        break;
      case "classPurpose":
        newErrors.classPurpose = value.trim()
          ? ""
          : "Class purpose is required";
        break;
      case "studentFirstName":
        newErrors.studentFirstName = value.trim()
          ? ""
          : "First name is required";
        break;
      case "studentLastName":
        newErrors.studentLastName = value.trim() ? "" : "Last name is required";
        break;
      case "grade":
        newErrors.grade = value.trim() ? "" : "Grade is required";
        break;
      case "email":
        newErrors.email = value.trim()
          ? /^\S+@\S+\.\S+$/.test(value)
            ? ""
            : "Invalid email address"
          : "Email is required";
        break;
      default:
        break;
    }

    if (name === "courseName") {
      const selectedCourse = courseNames.find(
        (course) => course.courseID === parseInt(value)
      );
      setFormData({
        ...formData,
        courseName: selectedCourse ? selectedCourse.courseName : "",
        courseID: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = Object.values(errors).every((error) => error === "");

    if (isValid) {
      try {
        await axiosClient.post(`/request`, formData);
        await handleFetchRequest();
        setSubmitted(true);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      console.log("Form has errors, cannot submit");
    }
  };

  const handleReset = () => {
    setFormData({
      courseName: "",
      courseID: "",
      departmentName: "",
      classPurpose: "tutorial",
      studentFirstName: user ? user.firstName : "",
      studentLastName: user ? user.surname : "",
      gender: user ? user.gender : "",
      school: "",
      grade: "",
      email: user ? user.email : "",
      phoneNumber: "",
    });
    setErrors({});
  };

  return (
    <div className={`class-request-modal ${showModal ? "show" : ""}`}>
      <div className="class-request-container">
        <div className="class-request-header">
          <h3>Class Request Form</h3>
          <button
            className="class-request-close-icon-button"
            onClick={() => setShowModal(false)}
          >
            <ClearOutlinedIcon />
          </button>
        </div>
        {loadingUser || loadingCourses ? (
          <p>Loading...</p>
        ) : !submitted ? (
          <div className="class-request-content">
            <form onSubmit={handleSubmit}>
              <div className="class-request-row">
                <label>Learning Area *</label>
                <select
                  id="departmentName"
                  name="departmentName"
                  value={formData.departmentName}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled={true}>
                    Select a Learning Area
                  </option>
                  {[
                    ...new Set(
                      courseNames.map((course) => course.departmentName)
                    ),
                  ].map((department) => (
                    <option key={department} value={department}>
                      {department}
                    </option>
                  ))}
                </select>
                {errors.departmentName && (
                  <span className="tutorial-form-error">
                    {errors.departmentName}
                  </span>
                )}
              </div>

              <div className="class-request-row">
                <label>Course Name *</label>
                <select
                  id="courseName"
                  name="courseName"
                  value={formData.courseID}
                  onChange={handleChange}
                  required
                  disabled={!formData.departmentName}
                >
                  <option value="" disabled={true}>
                    Select a Course
                  </option>
                  {courseNames
                    .filter(
                      (course) =>
                        course.departmentName === formData.departmentName
                    )
                    .map((course) => (
                      <option key={course.courseID} value={course.courseID}>
                        {course.courseName}
                      </option>
                    ))}
                </select>
                {errors.courseName && (
                  <span className="tutorial-form-error">
                    {errors.courseName}
                  </span>
                )}
              </div>

              <div className="class-request-row">
                <label>Class Purpose *</label>
                <div className="radio-group">
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="tutorial"
                      name="classPurpose"
                      value="tutorial"
                      checked={formData.classPurpose === "tutorial"}
                      onChange={handleChange}
                    />
                    <label htmlFor="tutorial">Tutoring</label>
                  </div>
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="private credit"
                      name="classPurpose"
                      value="private credit"
                      checked={formData.classPurpose === "private credit"}
                      onChange={handleChange}
                    />
                    <label htmlFor="private credit">
                      Credit Course
                    </label>
                  </div>
                </div>
                {errors.classPurpose && (
                  <span className="tutorial-form-error">
                    {errors.classPurpose}
                  </span>
                )}
              </div>

              <div className="class-request-row">
                <label>Student First Name *</label>
                <input
                  type="text"
                  id="studentFirstName"
                  name="studentFirstName"
                  value={formData.studentFirstName}
                  onChange={handleChange}
                  placeholder="Please enter STUDENT first name"
                  autoFocus
                  required
                  disabled={user ? true : false}
                />
                {errors.studentFirstName && (
                  <span className="tutorial-form-error">
                    {errors.studentFirstName}
                  </span>
                )}
              </div>

              <div className="class-request-row">
                <label>Student Last Name *</label>
                <input
                  type="text"
                  id="studentLastName"
                  name="studentLastName"
                  value={formData.studentLastName}
                  onChange={handleChange}
                  placeholder="Please enter STUDENT last name"
                  required
                  disabled={user ? true : false}
                />
                {errors.studentLastName && (
                  <span className="tutorial-form-error">
                    {errors.studentLastName}
                  </span>
                )}
              </div>

              <div className="class-request-row">
                <label>Gender </label>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="Other">Other</option>
                </select>
                {errors.gender && (
                  <span className="tutorial-form-error">{errors.gender}</span>
                )}
              </div>

              <div className="class-request-row">
                <label>Student School </label>
                <input
                  type="text"
                  id="school"
                  name="school"
                  value={formData.school}
                  onChange={handleChange}
                  placeholder="Please enter student school"
                />
                {errors.school && (
                  <span className="tutorial-form-error">{errors.school}</span>
                )}
              </div>

              <div className="class-request-row">
                <label>Student Grade *</label>
                <input
                  type="text"
                  id="grade"
                  name="grade"
                  value={formData.grade}
                  onChange={handleChange}
                  placeholder="Please enter student current grade"
                  required
                />
                {errors.grade && (
                  <span className="tutorial-form-error">{errors.grade}</span>
                )}
              </div>

              <div className="class-request-row">
                <label>Student Email Address *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Please enter STUDENT email"
                  required
                  disabled={user ? true : false}
                />
                {errors.email && (
                  <span className="tutorial-form-error">{errors.email}</span>
                )}
              </div>

              <div className="class-request-row">
                <label>Cell Phone </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  className="large-input"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Please enter cell phone"
                />
                {errors.phoneNumber && (
                  <span className="tutorial-form-error">
                    {errors.phoneNumber}
                  </span>
                )}
              </div>

              <div className="class-request-save">
                <input
                  type="submit"
                  value="Submit"
                  disabled={
                    !formData.departmentName ||
                    !formData.courseID ||
                    !formData.classPurpose ||
                    !formData.studentFirstName ||
                    !formData.studentLastName ||
                    !formData.grade ||
                    !formData.email ||
                    Object.values(errors).some((error) => error !== "")
                  }
                />
                <button type="button" onClick={handleReset}>
                  Reset
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div
            className="thank-you-message"
            style={{ height: "570px", textAlign: "center" }}
          >
            <br />
            <h4>Thank you for your message!</h4>
            <br />
          </div>
        )}
        <br />
        <br />
      </div>
    </div>
  );
};

export default ClassRequestFormModal;
