import React, { useState } from "react";
import "./dronesProgram.css";
import schoolLogo from "./images/logo-small.jpg";
import axiosClient from "../axiosClient";

function DroneForm({ onSubmit }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    age: "",
    grade: "",
    parentName: "",
    parentEmail: "",
    scheduleChoices: [],
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newErrors = { ...errors };
    switch (name) {
      case "name":
        newErrors.name = value.trim() ? "" : "Name is required";
        break;
      case "email":
        newErrors.email = value.trim()
          ? /^\S+@\S+\.\S+$/.test(value)
            ? ""
            : "Invalid email address"
          : "Email is required";
        break;
      case "phone":
        newErrors.phone = value.trim() ? "" : "Phone is required";
        break;
      case "grade":
        newErrors.grade = value.trim() ? "" : "Grade is required";
        break;
      case "parentName":
        newErrors.parentName = value.trim() ? "" : "Parent name is required";
        break;
      case "parentEmail":
        newErrors.parentEmail = value.trim()
          ? /^\S+@\S+\.\S+$/.test(value)
            ? ""
            : "Invalid email address"
          : "Parent email is required";
        break;
      // case "scheduleChoices":
      //   newErrors.scheduleChoices =
      //     formData.scheduleChoices.length > 0
      //       ? ""
      //       : "Please select at least one schedule";
      //   break;
      default:
        break;
    }

    setErrors(newErrors);

    if (type === "checkbox") {
      setFormData((prevData) => {
        let updatedScheduleChoices;
        if (checked) {
          updatedScheduleChoices = [...prevData.scheduleChoices, value];
        } else {
          updatedScheduleChoices = prevData.scheduleChoices.filter(
            (choice) => choice !== value
          );
        }
        return {
          ...prevData,
          [name]: updatedScheduleChoices,
        };
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="name">Name *</label>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
      />
      {errors.name && (
        <span className="registration-form-error">{errors.name}</span>
      )}

      <label htmlFor="email">Email *</label>
      <input
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      {errors.email && (
        <span className="registration-form-error">{errors.email}</span>
      )}

      <label htmlFor="phone">Phone *</label>
      <input
        type="text"
        id="phone"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
        required
      />
      {errors.phone && (
        <span className="registration-form-error">{errors.phone}</span>
      )}

      <label htmlFor="age">Age: </label>
      <input
        type="text"
        id="age"
        name="age"
        value={formData.age}
        onChange={handleChange}
      />

      <label htmlFor="grade">Grade *</label>
      <input
        type="text"
        id="grade"
        name="grade"
        value={formData.grade}
        onChange={handleChange}
        required
      />
      {errors.grade && (
        <span className="registration-form-error">{errors.grade}</span>
      )}

      <label htmlFor="parentName">Parent's Name *</label>
      <input
        type="text"
        id="parentName"
        name="parentName"
        value={formData.parentName}
        onChange={handleChange}
        required
      />
      {errors.parentName && (
        <span className="registration-form-error">{errors.parentName}</span>
      )}
      <label htmlFor="parentEmail">Parent's Email *</label>
      <input
        type="email"
        id="parentEmail"
        name="parentEmail"
        value={formData.parentEmail}
        onChange={handleChange}
        required
      />
      {errors.parentEmail && (
        <span className="registration-form-error">{errors.parentEmail}</span>
      )}

      <label>Select schedule(s) that will work for you * </label>
      <div>
        <input
          type="checkbox"
          id="choice1"
          name="scheduleChoices"
          value="Every Saturday 10am to 12nn"
          checked={formData.scheduleChoices.includes(
            "Every Saturday 10am to 12nn"
          )}
          onChange={handleChange}
        />
        <label htmlFor="choice1"> Every Saturday 10am to 12nn</label>
        <br />
        <input
          type="checkbox"
          id="choice2"
          name="scheduleChoices"
          value="Every Saturday 12nn to 2pm"
          checked={formData.scheduleChoices.includes(
            "Every Saturday 12nn to 2pm"
          )}
          onChange={handleChange}
        />
        <label htmlFor="choice2"> Every Saturday 12nn to 2pm</label>
        <br />
        <input
          type="checkbox"
          id="choice3"
          name="scheduleChoices"
          value="Every Saturday 2:30pm to 4:30pm"
          checked={formData.scheduleChoices.includes(
            "Every Saturday 2:30pm to 4:30pm"
          )}
          onChange={handleChange}
        />
        <label htmlFor="choice3"> Every Saturday 2:30pm to 4:30pm</label>
        <br />
        <input
          type="checkbox"
          id="choice4"
          name="scheduleChoices"
          value="Every Saturday 5pm to 7pm"
          checked={formData.scheduleChoices.includes(
            "Every Saturday 5pm to 7pm"
          )}
          onChange={handleChange}
        />
        <label htmlFor="choice4"> Every Saturday 5pm to 7pm</label>
        <br />
      </div>

      {formData.scheduleChoices.length <= 0 && (
        <span className="registration-form-error">
          Please select at least one schedule
        </span>
      )}
      <input
        type="submit"
        value="Submit"
        disabled={
          !formData.name ||
          !formData.email ||
          !formData.phone ||
          !formData.grade ||
          !formData.parentName ||
          !formData.parentEmail ||
          formData.scheduleChoices.length <= 0 ||
          Object.values(errors).some((error) => error !== "")
        }
      />
    </form>
  );
}

function DroneProgram() {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = async (formData) => {
    console.log("Form data:", formData);
    try {
      await axiosClient.post(`/droneRegistrationForms`, formData);
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="drone-program-page">
      <div className="drone-program-container">
        {!submitted ? (
          <div>
            <table>
              <tbody>
                <tr>
                  <td align="center" style={{ width: "100%", padding: "30px" }}>
                    <span
                      style={{
                        fontFamily: " Arial, sans-serif",
                        fontSize: "4vmin",
                        color: "#555555",
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      2023 - 2024 School Year Drones Curriculum
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "100%", padding: "0 15px" }}>
                    <div className="paragraph">
                      <span>
                        We will be forming, practicing and competing in the VEX
                        Aerial Drone Competition this year (
                        <a
                          href="https://roboticseducation.org/aerial-drone-competition/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Aerial Drone Competition - REC Foundation
                          (roboticseducation.org)
                        </a>
                        )
                        <br />
                        <br />
                        The competition features four levels of competition, as
                        follow:
                        <br />
                        <ul style={{ listStyle: "none" }}>
                          <li>
                            - Dedicated Autonomous Flight Layout – Designed to
                            use sensors of the CoDrone EDU and increase
                            programming success. Specially designed mats will be
                            placed on sections of the floor to aid in set up and
                            consistent sensor readings.
                          </li>
                          <br />
                          <li>
                            - Piloting Skills Challenge – One Drone - One Team -
                            Pilot your drone around an obstacle course to show
                            off your piloting skills.
                          </li>
                          <br />
                          <li>
                            - Two Drone Teamwork Challenge – Designed for two
                            teams to work together and highlight precision
                            flying skills, communication, and decision making.
                          </li>
                          <br />
                          <li>
                            - Communications Mission –{" "}
                            <span style={{ fontStyle: "italic" }}>
                              Teams interview with Judges about their Drone,
                              Programming and Competition Logbook Documentation
                            </span>
                          </li>
                        </ul>
                        <br />
                        <br />
                        We will learn and compete in all four areas.
                        <br />
                        <br />
                        Our main emphasis will be on programming, using first
                        Blockly programming system, then graduating to Python
                        when appropriate.
                        <br />
                        <br />
                        We will gain experience in loops, conditionals, using
                        sensors, version control, PID and documentation.
                        <br />
                        <br />
                        Physical Education – Pickleball
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <table>
              <tbody>
                <tr>
                  <td>
                    <span
                      style={{
                        fontFamily: "Arial, sans-serif",
                        fontSize: "2.2vmin",
                        color: "#000",
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      Regional Championship Host{" "}
                    </span>
                  </td>
                  <td>
                    <a
                      href="https://www.seattlestartup.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="Seattle Entrepreneurship Club"
                        className="head-logo"
                        src="https://static.wixstatic.com/media/802d2c_f1d6c00414354cd4972e42b3b68bc462~mv2.png/v1/crop/x_0,y_148,w_1000,h_686/fill/w_181,h_112,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/SEC_00_PNG.png"
                      />
                    </a>
                  </td>
                  <td>
                    <span
                      style={{
                        fontFamily: "Arial, sans-serif",
                        fontSize: "2.2vmin",
                        color: "#000",
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      Training partner{" "}
                    </span>
                  </td>

                  <td>
                    <img
                      alt="Seattle Voctech School"
                      className="head-logo"
                      src={schoolLogo}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="registeration-form-container">
              <h3>Drones Registration Form</h3>
              <h3>Please complete one for each student to be registered</h3>
              <h3>(* - Required Information)</h3>
              <br />
              <div className="registeration-form">
                <DroneForm onSubmit={handleSubmit} />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="submitted-page"
            style={{ height: "80vh", textAlign: "center" }}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <h4>
              Thank you for your registration, Seattle VocTech School will
              contact you when your schedule has been assigned!
            </h4>
            <br />
          </div>
        )}
      </div>
    </div>
  );
}

export default DroneProgram;
