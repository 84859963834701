import music1 from "./images/music_image001.png";
import music2 from "./images/music_image002.png";
import music3 from "./images/music_image003.png";
import "./collegeCap.css";
const CollegeCap = () => {
  return (
    <div className="college-cap-page">
      <div className="college-cap-container">
        <div className="WordSection1" style={{ layoutGrid: "15.6pt" }}>
          <h2 className="college-cap-header">
            "International Student-Music College Planning" Program
            <br />
            (here abbreviation as "ISMCP")
          </h2>

          <p className="college-cap-imgs">
            <img src={music1} alt="Music1" />
            <img src={music2} alt="Music2" />
            <img src={music3} alt="Music3" />
          </p>

          <p align="left">
            <b>
              <span>1</span>
            </b>
            <b>
              <span>．</span>
            </b>
            <b>
              <span> Highlights of ISMCP </span>
            </b>
          </p>

          <ul style={{ listStyle: "none" }}>
            <li>
              <h3>
                * Admission goals to Berklee Music College and other top ones in
                the USA and UK
              </h3>
            </li>

            <li>
              <h3>
                * AP courses with partial credits transferable to colleges
              </h3>
            </li>

            <li>
              <h3>
                * USA private high school diploma and waiver of TOFEL/IELTS{" "}
              </h3>
            </li>
          </ul>

          <p align="left">
            <span>&nbsp;</span>
          </p>

          <p>
            <b>
              <span>2</span>
            </b>
            <b>
              <span>．</span>
            </b>
            <b>
              <span>
                {" "}
                ISMCP content: SVT high school study and college planning{" "}
              </span>
            </b>
          </p>

          <p>
            <span>&nbsp;</span>
          </p>

          <p align="left">
            <b>
              <span>3</span>
            </b>
            <b>
              <span>．</span>
            </b>
            <b>
              <span> Why we SVT</span>
            </b>
          </p>
          <ul style={{ listStyle: "none" }}>
            <li>
              <h3>* USA local education organization and team</h3>
            </li>

            <li>
              <h3>
                * We are not only experienced in college planning, we are a full
                accredited private high school also with international student
                enrollment qualification for 6th - 12th grade.
              </h3>
            </li>

            <li>
              {" "}
              <h3>
                * Immediate contingent plan to failure to 1st target college; no
                waste school time through TRANFER program{" "}
              </h3>
            </li>

            <li>
              <h3
                style={{
                  fontSize: "2.5vmin",
                  fontFamily: "Arial, sans-serif",
                  fontWeight: "700",
                }}
              >
                * Colleges with corporations:
              </h3>
            </li>
            <ul style={{ listStyle: "none", fontSize: "2.5vmin" }}>
              <li>- Berklee College of Music</li>
              <li>- Manhattan School of Music</li>
              <li>- New England Conservatory of Music</li>
              <li>- San Francisco Conservatory of Music</li>
              <li>- Oberlin Conservatory</li>
              <li>And More...</li>
            </ul>
          </ul>
          <p align="left">
            <span>&nbsp;</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CollegeCap;
