import React, { useState } from "react";
import "./robotics.css";

const Robotics = () => {
  return (
    <div className="robotics-page">
      <div className="robotics-container">
        <div className="wsite-multicol">
          <table className="wsite-multicol-table">
            <tbody>
              <tr>
                <td
                  align="center"
                  className="wsite-multicol-col"
                  style={{ width: "100%", padding: "30px" }}
                >
                  <span></span>
                </td>
              </tr>
              <tr>
                <td
                  className="wsite-multicol-col"
                  style={{ width: "100%", padding: "0 15px" }}
                >
                  <div className="paragraph">
                    <span>
                      SVT teaches VEX Robotics systems (used in the fastest
                      growing robotics leagues in the world): IQ (using plastic
                      parts that snap together) and EDR (a metal building system
                      with more powerful motors and controllers). Campers work
                      individually or in small-groups to design, build, program,
                      test and revise robots to accomplish specific missions.
                      Along the way, we introduce them to all the major
                      programming and building structures used in competitive
                      robotics: Tank and Holonomic drives, 4-bar, scissor and
                      double-reverse 4-bar lifts.
                      <br />
                      <br />
                      Sessions are NOT necessarily sequential. Content will
                      depend on the skills and experience of each camper. (ie.
                      Starting with Session 2 is OK. Likewise, doing both
                      Session 1 and 2 is also OK - the level of challenge will
                      increase.)
                      <br />
                      <br />
                      As fun as robotics is, we do recognize that all day, every
                      day is a LOT of robotics, so we've planned daily outdoor
                      activities to get some sun, exercise and keep the days
                      balanced.
                      <br />
                      <br />
                      Every session is two weeks, Monday to Friday from 9:00 AM
                      to 4:00 PM. (Before and after care is available. A $20 fee
                      will be charged if camper joins the afternoon care without
                      proper registration).
                      <br />
                      <br />
                      VEX IQ Robolab Summer Camp (Ages 7-11)
                      <br />
                      VEX EDR Robolab Summer Camp (Ages 12-15)
                      <br />
                      <br />
                      Please email contact@seattlevt.org for additional
                      information and REGISTER.
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Robotics;
