import axios from "axios";
import { GlobalConstant } from "./GlobalConstant";

const apiUrl = GlobalConstant.REACT_APP_BACKENDURL;

const axiosClient = axios.create({
  baseURL: apiUrl,
});

// Fetch CSRF token dynamically
async function getCsrfToken() {
  const response = await axios.get(`${apiUrl}/csrf-token`);
  return response.data.csrfToken;
}

axiosClient.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("ACCESS_TOKEN");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const isProduction = process.env.REACT_APP_ENV.trim() === "production";
  console.log("isProduction: |" + isProduction + "|");
  if (!isProduction) {
    // Get CSRF token and add to the headers
    const csrfToken = await getCsrfToken();
    console.log("CSRF token:" + csrfToken);
    if (csrfToken) {
      config.headers["X-CSRF-TOKEN"] = csrfToken;
    }
  }

  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      localStorage.removeItem("ACCESS_TOKEN");
    }
    throw error;
  }
);

export default axiosClient;
