import React from "react";
import Modal from "react-modal";
import "./CommentsModal.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
const CommentsModal = ({ isOpen, onRequestClose, comments }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Comments Modal"
      className="comments-modal"
      overlayClassName="comments-modal-overlay"
    >
      <button
        className="close-useredit-button"
        onClick={() => onRequestClose(false)}
      >
        <ClearOutlinedIcon />
      </button>
      <h2>Comments</h2>
      <div className="comments-content">
        {comments ? comments : "No comments available"}
      </div>
    </Modal>
  );
};

export default CommentsModal;
