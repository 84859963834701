import yunica from "./thumbnail/case-esl-yunica.jpg";
import dragon from "./thumbnail/case-esl-dragon.jpg";
import "./eslCase.css";

const EslCase = () => {
  return (
    <div className="esl-case-page">
      <div className="esl-case-container">
        <h2 className="esl-case-header" align="center">
          ESL Case
        </h2>
        <ol className="esl-case-content" type="1">
          <li>
            Yunica Zhao
            <div className="wsite-multicol">
              <table className="wsite-multicol-table">
                <tbody>
                  <tr>
                    <td className="description-container">
                      <div className="description">
                        Yunica 4-4.5 grade level TESTED improvement in 1.5
                        years.
                        <br />
                        <br />
                        Yunica Zhao is a Chinese national that is finishing her
                        second year with us after doing 1 year at another high
                        school in Washington State. When she entered, we
                        assessed her English using the California Acheivement
                        Test (CAT). She achieved scores of 3.5th grade level for
                        Vocabulary and 8.7th grade level for Comprehension.
                        While these levels may sound low, especially the
                        Vocabulary, they are, in fact, typical (vocabulary) and
                        above average (compehension). Yunica is an good student
                        and after 1.5 years of study has increased incredibly.
                        Currently she is working on 8th grade vocabulary
                        material and 12th grade material in Comprehension.
                        That’s a 4-4.5 grade level increase in only 1.5
                        years!!!! If Yunica had made better use of her
                        opportunities to speak in English, her gain would have
                        been over 6 grade levels without doubt.
                      </div>
                    </td>
                    <td align="center" className="img-container">
                      <img src={yunica} height="300" alt="yunica" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li>
            Dragon Li
            <div className="wsite-multicol">
              <table className="wsite-multicol-table">
                <tbody>
                  <tr>
                    <td align="center" className="img-container">
                      <img src={dragon} height="300" alt="dragon" />
                    </td>
                    <td className="description-container">
                      <div className="description">
                        Dragon 1-2 grade level improvement in 4 months.
                        <br />
                        <br />
                        Dragon Li is a new student starting with us in January
                        2018. When he joined us, he tested at 3.9th grade level
                        for Comprehension and 4.8th grade level for Vocabulary.
                        After 4 months he is working on 6th grade material in
                        both aspects of English skill. Unlike Yunica, Dragon has
                        focused on using every opportunity to speak in English,
                        even when speaking with home country people. As such,
                        his spoken English and confidence is rising dramatically
                        and he is already comfortable leading everyday life in
                        English without assistance.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default EslCase;
