// ForgotPassword.js
import React, { useState } from "react";
import axiosClient from "../../axiosClient";
import "./forgotPassword.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

function ForgotPassword({ setShowForgotPasswordModal }) {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const validateEmail = (email) => {
    return /^\S+@\S+\.\S+$/.test(email);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    let newErrors = { ...errors };

    newErrors.email = value.trim()
      ? validateEmail(value)
        ? ""
        : "Invalid email address"
      : "Email is required";

    setErrors(newErrors);
    setEmail(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = !errors.email && email.trim();

    if (isValid) {
      try {
        await axiosClient.post(`/forgetPassword`, { email });
        setSubmitted(true);
        alert("A password reset link has been sent to your email address.");
        setShowForgotPasswordModal(false);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      console.log("Form has errors, cannot submit");
    }
  };

  return (
    <div className="forgotpassword-modal">
      <div className="forgotpassword-container">
        <button
          className="forgotpassword-close-icon-button"
          onClick={() => setShowForgotPasswordModal(false)}
        >
          <ClearOutlinedIcon />
        </button>
        <form className="forgotpassword-form" onSubmit={handleSubmit}>
          <h2>Password Reset</h2>
          <p>
            Enter your SVT High School System email address you have listed in
            the system, and press submit: a unique password reset link will be
            emailed to you.
          </p>
          <div className="forgotpassword-form-group">
            {/* <label htmlFor="email">Email:</label> */}
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
              required
              placeholder="Enter your email"
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="forgotpassword-form-actions">
            <button type="submit" className="forgotpassword-submit-button">
              Submit
            </button>
            {/* <button
              type="button"
              className="forgotpassword-cancel-button"
              onClick={() => setShowForgotPasswordModal(false)}
            >
              Cancel
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
