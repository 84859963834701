import CloseIcon from "@mui/icons-material/Close";
import { Button, Divider, TextField } from "@mui/material";
import React, { useState } from "react";
import TimezoneSelect from "react-timezone-select";
import "./classRecurrenceModal.css";
import moment from "moment-timezone";

function ClassRecurrenceModal({ setShowModal, classForm, sendBack }) {
  const [recurrenceForm, setRecurrenceForm] = useState({
    ...classForm,
    frequency: classForm.frequency ? classForm.frequency : "daily",
    interval: classForm.interval ? classForm.interval : 1,
    weekDays: classForm.byDate ? classForm.byDate.split(",") : [],
  });
  const initializeRecurrenceRangeEnd = () => {
    var type;
    if (classForm.endDate) {
      type = "endByDate";
    } else if (classForm.numOccurrences) {
      type = "endByOccurrence";
    } else {
      type = "noEnd";
    }
    return type;
  };
  const [recurrenceRangeEnd, setRecurrenceRangeEnd] = useState(
    initializeRecurrenceRangeEnd()
  );

  const toggleRecurrenceType = () => {
    var type;
    if (!classForm.frequency) {
      type = "daily";
    } else if (["daily", "daily-weekday"].includes(classForm.frequency)) {
      type = "daily";
    } else if (classForm.frequency === "weekly") {
      type = "weekly";
    } else if (["monthly-day", "monthly-date"].includes(classForm.frequency)) {
      type = "monthly-day";
    } else {
      type = "yearly-day";
    }
    return type;
  };
  const [recurrenceType, setRecurrenceType] = useState(toggleRecurrenceType());
  const monthlyConstant = {
    order: [
      { name: "first", value: 1 },
      { name: "second", value: 2 },
      { name: "third", value: 3 },
      { name: "fourth", value: 4 },
      { name: "last", value: 0 },
    ],
    day: [
      "day",
      "weekday",
      "weekend day",
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleClose = () => {
    setShowModal(false);
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setRecurrenceForm((prevData) => {
        let updatedWeekdays;
        if (checked) {
          updatedWeekdays = [...prevData.weekDays, value];
        } else {
          updatedWeekdays = prevData.weekDays.filter(
            (choice) => choice !== value
          );
        }
        return {
          ...prevData,
          [name]: updatedWeekdays,
        };
      });
    } else {
      if (name === "frequency") {
        resetForm(value);
      }
      setRecurrenceForm((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      if (name === "startDate") {
        const newDate = new Date(value).getTime();
        if (
          recurrenceForm.endDate &&
          newDate > new Date(recurrenceForm.endDate).getTime()
        ) {
          setRecurrenceForm((prev) => ({
            ...prev,
            endDate: value,
          }));
        }
      }
    }
  };

  const resetForm = (type) => {
    switch (type) {
      case "monthly-day":
        setRecurrenceForm((prevData) => ({
          ...prevData,
          interval: 1,
          byDay: moment().date(),
          byDate: null,
          byMonth: null,
          weekDays: [],
        }));
        break;
      case "yearly-day":
        setRecurrenceForm((prevData) => ({
          ...prevData,
          interval: 1,
          byDay: moment().date(),
          byDate: null,
          byMonth: "January",
          weekDays: [],
        }));
        break;
      case "monthly-date":
        setRecurrenceForm((prevData) => ({
          ...prevData,
          interval: 1,
          byDay: 1,
          byDate: "day",
          byMonth: null,
          weekDays: [],
        }));
        break;
      case "yearly-date":
        setRecurrenceForm((prevData) => ({
          ...prevData,
          interval: 1,
          byDay: 1,
          byDate: "day",
          byMonth: "January",
          weekDays: [],
        }));
        break;

      default:
        setRecurrenceForm((prevData) => ({
          ...prevData,
          interval: 1,
          byDay: null,
          byDate: null,
          byMonth: null,
          weekDays: [],
        }));
        break;
    }
  };

  const handleRecurrenceType = (event) => {
    const { value } = event.target;
    resetForm(value);
    setRecurrenceType(value);
    setRecurrenceForm((prevData) => ({
      ...prevData,
      frequency: value,
    }));
  };

  const handleRecurrenceRangeEnd = (event) => {
    const { id } = event.target;

    switch (id) {
      case "endDate":
        setRecurrenceRangeEnd("endByDate");
        setRecurrenceForm((prevData) => ({
          ...prevData,
          endDate: moment(recurrenceForm.startDate)
            .add(3, "M")
            .format("YYYY-MM-DD"),
          numOccurrences: null,
        }));
        break;
      case "numOccurrences":
        setRecurrenceRangeEnd("endByOccurrence");
        setRecurrenceForm((prevData) => ({
          ...prevData,
          endDate: "",
          numOccurrences: 1,
        }));
        break;
      case "noEnd":
        setRecurrenceRangeEnd("noEnd");
        setRecurrenceForm((prevData) => ({
          ...prevData,
          endDate: "",
          numOccurrences: null,
        }));
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    let updatedRecurrenceForm = {
      ...recurrenceForm,
      scheduleType: "recurring",
    };

    if (recurrenceForm.weekDays.length > 0) {
      const weekDaysOrder = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];

      const sortedDays = [...recurrenceForm.weekDays].sort((a, b) => {
        return weekDaysOrder.indexOf(a) - weekDaysOrder.indexOf(b);
      });
      updatedRecurrenceForm = {
        ...updatedRecurrenceForm,
        byDate: sortedDays.join(","),
      };
      setRecurrenceForm(updatedRecurrenceForm);
    }

    sendBack(updatedRecurrenceForm);
    setShowModal(false);
  };

  function DailyContent() {
    return (
      <div className="recurrence-content-col">
        <div className="radio-choice">
          <input
            type="radio"
            id="daily"
            name="frequency"
            value="daily"
            checked={recurrenceForm.frequency === "daily"}
            onChange={handleChange}
          />
          <label htmlFor="days">
            Every
            <input
              type="number"
              id="interval"
              name="interval"
              min="1"
              value={recurrenceForm.interval}
              onChange={handleChange}
              disabled={recurrenceForm.frequency !== "daily"}
            />
            day{"("}s{")"}
          </label>
        </div>
        <div className="radio-choice">
          <input
            type="radio"
            id="daily-weekday"
            name="frequency"
            value="daily-weekday"
            checked={recurrenceForm.frequency === "daily-weekday"}
            onChange={handleChange}
          />
          <label htmlFor="daily-weekday">Every weekday </label>
        </div>
      </div>
    );
  }

  function WeeklyContent() {
    return (
      <div className="recurrence-content-col">
        <div className="weekly-recurrence">
          <label htmlFor="weekly-recurrence">
            Recur every
            <input
              type="number"
              id="interval"
              name="interval"
              min="1"
              value={recurrenceForm.interval}
              onChange={handleChange}
            />
            week{"("}s{")"} on:
          </label>
        </div>
        <div className="weekly-choices">
          <div className="weekly-choice">
            <input
              type="checkbox"
              id="sunday"
              name="weekDays"
              value="Sunday"
              onChange={handleChange}
              checked={recurrenceForm.weekDays.includes("Sunday")}
            />
            <label htmlFor="sunday"> Sunday</label>
          </div>

          <div className="weekly-choice">
            <input
              type="checkbox"
              id="monday"
              name="weekDays"
              value="Monday"
              onChange={handleChange}
              checked={recurrenceForm.weekDays.includes("Monday")}
            />
            <label htmlFor="monday"> Monday</label>
          </div>
          <div className="weekly-choice">
            <input
              type="checkbox"
              id="tuesday"
              name="weekDays"
              value="Tuesday"
              onChange={handleChange}
              checked={recurrenceForm.weekDays.includes("Tuesday")}
            />
            <label htmlFor="tuesday"> Tuesday</label>
          </div>
          <div className="weekly-choice">
            <input
              type="checkbox"
              id="wednesday"
              name="weekDays"
              value="Wednesday"
              onChange={handleChange}
              checked={recurrenceForm.weekDays.includes("Wednesday")}
            />
            <label htmlFor="wednesday"> Wednesday</label>
          </div>
          <div className="weekly-choice">
            <input
              type="checkbox"
              id="thursday"
              name="weekDays"
              value="Thursday"
              onChange={handleChange}
              checked={recurrenceForm.weekDays.includes("Thursday")}
            />
            <label htmlFor="thursday"> Thursday</label>
          </div>
          <div className="weekly-choice">
            <input
              type="checkbox"
              id="friday"
              name="weekDays"
              value="Friday"
              onChange={handleChange}
              checked={recurrenceForm.weekDays.includes("Friday")}
            />
            <label htmlFor="friday"> Friday</label>
          </div>
          <div className="weekly-choice">
            <input
              type="checkbox"
              id="saturday"
              name="weekDays"
              value="Saturday"
              onChange={handleChange}
              checked={recurrenceForm.weekDays.includes("Saturday")}
            />
            <label htmlFor="saturday"> Saturday</label>
          </div>
        </div>
      </div>
    );
  }

  function MonthlyContent() {
    return (
      <div className="recurrence-content-col">
        <div className="radio-choice">
          <input
            type="radio"
            id="monthly-day"
            name="frequency"
            value="monthly-day"
            checked={recurrenceForm.frequency === "monthly-day"}
            onChange={handleChange}
          />
          <label htmlFor="monthly-day">
            Day
            <input
              type="number"
              id="byDay"
              name="byDay"
              min="1"
              value={
                recurrenceForm.frequency === "monthly-day"
                  ? recurrenceForm.byDay
                  : null
              }
              onChange={handleChange}
              disabled={recurrenceForm.frequency !== "monthly-day"}
            />
            of every
            <input
              type="number"
              id="interval"
              name="interval"
              min="1"
              value={
                recurrenceForm.frequency === "monthly-day"
                  ? recurrenceForm.interval
                  : null
              }
              onChange={handleChange}
              disabled={recurrenceForm.frequency !== "monthly-day"}
            />
            month{"("}s{")"}
          </label>
        </div>
        <div className="radio-choice">
          <input
            type="radio"
            id="monthly-date"
            name="frequency"
            value="monthly-date"
            checked={recurrenceForm.frequency === "monthly-date"}
            onChange={handleChange}
          />
          <label htmlFor="monthly-date">
            The{" "}
            <select
              id="byDay"
              name="byDay"
              value={
                recurrenceForm.frequency === "monthly-date"
                  ? recurrenceForm.byDay
                  : -1
              }
              onChange={handleChange}
              disabled={recurrenceForm.frequency !== "monthly-date"}
            >
              <option key={-1} disabled={true} value={-1}></option>;
              {monthlyConstant.order.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
            </select>
            <select
              id="byDate"
              name="byDate"
              value={
                recurrenceForm.frequency === "monthly-date"
                  ? recurrenceForm.byDate
                  : ""
              }
              onChange={handleChange}
              disabled={recurrenceForm.frequency !== "monthly-date"}
            >
              <option key={-1} disabled={true} value=""></option>;
              {monthlyConstant.day.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
            of every
            <input
              type="number"
              id="interval"
              name="interval"
              min="1"
              value={
                recurrenceForm.frequency === "monthly-date"
                  ? recurrenceForm.interval
                  : null
              }
              onChange={handleChange}
              disabled={recurrenceForm.frequency !== "monthly-date"}
            />
            month{"("}s{")"}{" "}
          </label>
        </div>
      </div>
    );
  }

  function YearlyContent() {
    return (
      <div className="recurrence-content-col">
        <div className="yearly-recurrence">
          <label htmlFor="yearly-recurrence">
            Recur every
            <input
              type="number"
              id="interval"
              name="interval"
              min="1"
              value={recurrenceForm.interval}
              onChange={handleChange}
            />
            year{"("}s{")"}
          </label>
        </div>

        <div className="radio-choice">
          <input
            type="radio"
            id="yearly-day"
            name="frequency"
            value="yearly-day"
            checked={recurrenceForm.frequency === "yearly-day"}
            onChange={handleChange}
          />
          <label htmlFor="yearly-day">
            On:{" "}
            <select
              id="byMonth"
              name="byMonth"
              value={
                recurrenceForm.frequency === "yearly-day"
                  ? recurrenceForm.byMonth
                  : ""
              }
              onChange={handleChange}
              disabled={recurrenceForm.frequency !== "yearly-day"}
            >
              <option key={-1} disabled={true}></option>;
              {months.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
            <input
              type="number"
              id="byDay"
              name="byDay"
              min="1"
              max="31"
              value={
                recurrenceForm.frequency === "yearly-day"
                  ? recurrenceForm.byDay
                  : ""
              }
              disabled={recurrenceForm.frequency !== "yearly-day"}
              onChange={handleChange}
            />
          </label>
        </div>

        <div className="radio-choice">
          <input
            type="radio"
            id="yearly-date"
            name="frequency"
            value="yearly-date"
            checked={recurrenceForm.frequency === "yearly-date"}
            onChange={handleChange}
          />
          <label htmlFor="yearly-date">
            On the:{" "}
            <select
              id="byDay"
              name="byDay"
              value={
                recurrenceForm.frequency === "yearly-date"
                  ? recurrenceForm.byDay
                  : -1
              }
              onChange={handleChange}
              disabled={recurrenceForm.frequency !== "yearly-date"}
            >
              <option key={-1} disabled={true} value={-1}></option>;
              {monthlyConstant.order.map((option, index) => {
                return (
                  <option key={index} value={option.value}>
                    {option.name}
                  </option>
                );
              })}
            </select>
            <select
              id="byDate"
              name="byDate"
              value={
                recurrenceForm.frequency === "yearly-date"
                  ? recurrenceForm.byDate
                  : ""
              }
              onChange={handleChange}
              disabled={recurrenceForm.frequency !== "yearly-date"}
            >
              <option key={-1} disabled={true} value=""></option>;
              {monthlyConstant.day.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
            of{" "}
            <select
              id="byMonth"
              name="byMonth"
              value={
                recurrenceForm.frequency === "yearly-date"
                  ? recurrenceForm.byMonth
                  : ""
              }
              onChange={handleChange}
              disabled={recurrenceForm.frequency !== "yearly-date"}
            >
              <option key={-1} disabled={true} value=""></option>;
              {months.map((option, index) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
      </div>
    );
  }

  console.log(classForm);

  return (
    <div className="class-recurrence-modal">
      <div className="class-recurrence-container">
        <div className="class-recurrence-header">
          <h3>Class Recurrence</h3>
          <button
            style={{ background: "white", border: "none" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="class-recurrence-content">
          <form className="class-recurrence-form">
            <fieldset className="class-time-content">
              <legend>Class Time</legend>
              <div className="class-time-row">
                <label htmlFor="start">Start: </label>

                <TextField
                  id="startTime"
                  name="startTime"
                  type="time"
                  variant="standard"
                  value={recurrenceForm.startTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "3vmin",
                      fontSize: "1.2vmin",
                      width: "10vmin",
                    },
                  }}
                  onChange={handleChange}
                />
                <TimezoneSelect
                  value={recurrenceForm.timeZone}
                  onChange={(event) => {
                    setRecurrenceForm((prevForm) => ({
                      ...prevForm,
                      timeZone: event.value,
                    }));
                  }}
                />
              </div>
              <div className="class-time-row">
                <label htmlFor="start">End: </label>

                <TextField
                  id="endTime"
                  name="endTime"
                  type="time"
                  variant="standard"
                  value={recurrenceForm.endTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "100%",
                      fontSize: "1.2vmin",
                      width: "10vmin",
                    },
                  }}
                  onChange={handleChange}
                />
                <TimezoneSelect
                  value={recurrenceForm.timeZone}
                  onChange={(event) => {
                    setRecurrenceForm((prevForm) => ({
                      ...prevForm,
                      timeZone: event.value,
                    }));
                  }}
                />
              </div>
            </fieldset>

            <fieldset className="recurrence-pattern-content">
              <legend>Recurrence pattern</legend>
              <div className="recurrence-type-col">
                <div className="radio-choice">
                  <input
                    type="radio"
                    id="daily"
                    name="daily"
                    value="daily"
                    checked={recurrenceType === "daily"}
                    onChange={handleRecurrenceType}
                  />
                  <label htmlFor="daily">Daily</label>
                </div>
                <div className="radio-choice">
                  <input
                    type="radio"
                    id="weekly"
                    name="weekly"
                    value="weekly"
                    checked={recurrenceType === "weekly"}
                    onChange={handleRecurrenceType}
                  />
                  <label htmlFor="weekly">Weekly</label>
                </div>
                <div className="radio-choice">
                  <input
                    type="radio"
                    id="monthly-day"
                    name="monthly-day"
                    value="monthly-day"
                    checked={recurrenceType === "monthly-day"}
                    onChange={handleRecurrenceType}
                  />
                  <label htmlFor="monthly-day">Monthly</label>
                </div>
                <div className="radio-choice">
                  <input
                    type="radio"
                    id="yearly-day"
                    name="yearly-day"
                    value="yearly-day"
                    checked={recurrenceType === "yearly-day"}
                    onChange={handleRecurrenceType}
                  />
                  <label htmlFor="yearly-day">Yearly</label>
                </div>
              </div>
              <Divider
                orientation="vertical"
                flexItem
                style={{ marginLeft: "2vmin", marginRight: "2vmin" }}
              />

              {recurrenceType === "daily" && <DailyContent />}
              {recurrenceType === "weekly" && <WeeklyContent />}
              {recurrenceType === "monthly-day" && <MonthlyContent />}
              {recurrenceType === "yearly-day" && <YearlyContent />}
            </fieldset>

            <fieldset className="recurrence-range-content">
              <legend>Range of recurrence</legend>
              <div className="recurrence-range-start">
                <label htmlFor="start">Start: </label>
                <TextField
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "3vmin",
                      fontSize: "1.2vmin",
                    },
                  }}
                  id="startDate"
                  name="startDate"
                  type="date"
                  variant="standard"
                  value={recurrenceForm.startDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />
              </div>
              <Divider
                orientation="vertical"
                flexItem
                style={{ marginLeft: "2vmin", marginRight: "2vmin" }}
              />
              <div className="recurrence-range-end">
                <div className="radio-choice">
                  <input
                    type="radio"
                    id="endDate"
                    name="endDate"
                    value="endDate"
                    checked={recurrenceRangeEnd === "endByDate"}
                    onChange={handleRecurrenceRangeEnd}
                  />
                  <label htmlFor="days">End by:</label>
                  <TextField
                    id="endDate"
                    name="endDate"
                    type="date"
                    variant="standard"
                    value={recurrenceForm.endDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "3vmin",
                        fontSize: "1.2vmin",
                      },
                    }}
                    onChange={handleChange}
                    disabled={recurrenceRangeEnd !== "endByDate"}
                  />
                </div>
                <div className="radio-choice">
                  <input
                    type="radio"
                    id="numOccurrences"
                    name="numOccurrences"
                    value="numOccurrences"
                    checked={recurrenceRangeEnd === "endByOccurrence"}
                    onChange={handleRecurrenceRangeEnd}
                  />
                  <label htmlFor="every">
                    End after{" "}
                    <input
                      type="number"
                      id="numOccurrences"
                      name="numOccurrences"
                      min={1}
                      onChange={handleChange}
                      value={recurrenceForm.numOccurrences ?? ""}
                      disabled={recurrenceRangeEnd !== "endByOccurrence"}
                    />{" "}
                    occurrences
                  </label>
                </div>
                <div className="radio-choice">
                  <input
                    type="radio"
                    id="noEnd"
                    name="noEnd"
                    value="noEnd"
                    checked={recurrenceRangeEnd === "noEnd"}
                    onChange={handleRecurrenceRangeEnd}
                  />
                  <label htmlFor="noEnd">No end date</label>
                </div>
              </div>
            </fieldset>

            <div className="class-recurrence-buttons">
              <Button
                variant="outlined"
                style={{
                  marginLeft: "2vmin",
                  marginRight: "2vmin",
                  fontSize: "1.2vmin",
                }}
                onClick={handleSubmit}
              >
                Okay
              </Button>
              <Button
                variant="outlined"
                style={{
                  marginLeft: "2vmin",
                  marginRight: "2vmin",
                  fontSize: "1.2vmin",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ClassRecurrenceModal;
