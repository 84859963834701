import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import Academy from "./components/academy";
import CollegeCap from "./components/collegeCap";
import ContactUs from "./components/contactUs";
import Footer from "./components/footer";
import Home from "./components/home";
import Robotics from "./components/robotics";
import STEAM from "./components/steam";

import ApplicationProcess from "./components/applicationProcess";
import CollegeCase from "./components/collegeCase";
import DroneProgram from "./components/dronesProgram";
import EslCase from "./components/eslCase";
import MissionStatement from "./components/missionStatement";
import UserLogin from "./components/modals/userLogin";
import StaffTeam from "./components/staffTeam";
import WelcomeLetter from "./components/welcomeLetter";
// import classRequestFormModal from "./components/modals/classRequestFormModal";
import InterschoolCoop from "./components/interschoolCoop";
import ForgotPassword from "./components/modals/forgotPassword";
import ScrollToTop from "./components/scrollToTop";
import TutorialandCreditCourse from "./components/tutorialandCreditCourse";
import UserHome from "./components/userHome";
import { ContextProvider } from "./contexts/contextProvider";

function App() {
  function RoutesWithNavbar() {
    return (
      <React.Fragment>
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="/academy" element={<Academy />} />
            <Route path="/college-cap" element={<CollegeCap />} />
            <Route path="/drones-program" element={<DroneProgram />} />
            <Route path="/steam" element={<STEAM />} />
            <Route path="/robotics" element={<Robotics />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/tutorial-credit"
              element={<TutorialandCreditCourse />}
            />

            <Route
              path="/application-process"
              element={<ApplicationProcess />}
            />
            <Route path="/welcome-letter" element={<WelcomeLetter />} />
            <Route path="/mission-statement" element={<MissionStatement />} />
            <Route path="/college-case" element={<CollegeCase />} />
            <Route path="/esl-case" element={<EslCase />} />
            <Route path="/staff-team" element={<StaffTeam />} />
            <Route
              path="/user-login"
              element={<UserLogin setShowModal={undefined} />}
            />
            <Route
              path="/forgot-password"
              element={
                <ForgotPassword setShowForgotPasswordModal={undefined} />
              }
            />
            <Route path="/interschool" element={<InterschoolCoop />} />
            <Route path="/user-home" element={<UserHome />} />
          </Routes>
        </div>
      </React.Fragment>
    );
  }
  return (
    <div className="App">
      <ContextProvider>
        <Router basename={"/"}>
          {/* <ScrollToTop> */}
          <Routes>
            <Route path="*" element={<RoutesWithNavbar />} />
          </Routes>
          {/* </ScrollToTop> */}
          <Footer />
        </Router>
      </ContextProvider>
    </div>
  );
}

export default App;
