import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { registerAllModules } from "handsontable/registry";
import Cookies from "js-cookie";
import moment from "moment-timezone";
import { GlobalConstant } from "../GlobalConstant";
import axiosClient from "../axiosClient";
import ClassRequestFormModal from "./modals/classRequestFormModal";
import CreateEditClassModal from "./modals/createEditClassModal";
import UserEdit from "./modals/userEdit";
import CommentsModal from "./modals/CommentsModal"; // Import the new modal
import "./userHome.css";
import TimezoneSelect, {
  useTimezoneSelect,
  allTimezones,
} from "react-timezone-select";

registerAllModules();

function UserHome() {
  const [title, setTitle] = useState("Dashboard");
  const [showCreateNewClass, setShowCreateNewClass] = useState(false);
  const [showEditRegisteredClass, setShowEditRegisteredClass] = useState(false);
  const [showEditUpcomingClass, setShowEditUpcomingClass] = useState(false);
  const [user, setUser] = useState(null);
  const [requestWidget, setRequestWidget] = useState([]);
  const [registeredClasses, setRegisteredClasses] = useState([]);
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [currentRequestPage, setCurrentRequestPage] = useState(0);
  const [currentRegisteredPage, setCurrentRegisteredPage] = useState(0);
  const [currentUpcomingPage, setCurrentUpcomingPage] = useState(0);

  const [entriesPerPageRequests, setEntriesPerPageRequests] = useState(10);
  const [entriesPerPageRegistered, setEntriesPerPageRegistered] = useState(10);
  const [entriesPerPageUpcoming, setEntriesPerPageUpcoming] = useState(10);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editRequestId, setEditRequestId] = useState(null);
  const [editRequestData, setEditRequestData] = useState(null);
  const [showClassRequestModal, setShowClassRequestModal] = useState(false);

  const [requestFilters, setRequestFilters] = useState({
    lastModified: "",
    status: "",
    requestor: "",
    type: "",
    courseName: "",
    className: "",
    purpose: "",
    comments: "",
  });

  const [registeredFilters, setRegisteredFilters] = useState({
    dateEnrolled: "",
    dateUnenrolled: "",
    courseName: "",
    className: "",
    teacherNames: "",
    studentNames: "",
  });

  const [upcomingFilters, setUpcomingFilters] = useState({
    startDate: "",
    time: "",
    timeZone: "",
    localTime: "",
    className: "",
    teacherNames: "",
    studentNames: "",
    classLocation: "",
  });

  const [loadingRequests, setLoadingRequests] = useState(true);
  const [loadingRegisteredClasses, setLoadingRegisteredClasses] =
    useState(true);
  const [loadingUpcomingClasses, setLoadingUpcomingClasses] = useState(true);

  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [currentComments, setCurrentComments] = useState("");

  const handleNavigation = (newTitle, url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      setTitle(newTitle);
    }
  };

  const handleFetch = () => {
    fetchRequestWidget();
    fetchRegisteredClasses();
    fetchUpcomingClasses();
  };

  useEffect(() => {
    const fetchUser = async () => {
      const accessToken = localStorage.getItem("ACCESS_TOKEN");
      if (accessToken) {
        try {
          const response = await axiosClient.get(`/user`);
          setUser(response.data);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      const userId = user.gibbonPersonID;
      setEntriesPerPageRequests(
        parseInt(Cookies.get(`entriesPerPageRequests_${userId}`) || "10")
      );
      setEntriesPerPageRegistered(
        parseInt(Cookies.get(`entriesPerPageRegistered_${userId}`) || "10")
      );
      setEntriesPerPageUpcoming(
        parseInt(Cookies.get(`entriesPerPageUpcoming_${userId}`) || "10")
      );
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      handleFetch();
    }
  }, [user]);

  const fetchRequestWidget = async () => {
    setLoadingRequests(true);
    try {
      const response = await axiosClient.get(
        `/requestWidget?gibbonPersonId=${user?.gibbonPersonID}`
      );
      setRequestWidget(response.data);
      setLoadingRequests(false);
    } catch (error) {
      console.error("Error fetching request widget data:", error);
      setLoadingRequests(false);
    }
  };

  const fetchRegisteredClasses = async () => {
    setLoadingRegisteredClasses(true);
    try {
      const response = await axiosClient.get(
        `/registeredClasses?gibbonPersonId=${user?.gibbonPersonID}`
      );
      setRegisteredClasses(response.data);
      setLoadingRegisteredClasses(false);
    } catch (error) {
      console.error("Error fetching registered classes data:", error);
      setLoadingRegisteredClasses(false);
    }
  };

  const fetchUpcomingClasses = async () => {
    setLoadingUpcomingClasses(true);
    try {
      const response = await axiosClient.get(`/upcomingClasses`);
      setUpcomingClasses(response.data);
      setLoadingUpcomingClasses(false);
    } catch (error) {
      console.error("Error fetching upcoming classes data:", error);
      setLoadingUpcomingClasses(false);
    }
  };

  const labelStyle = "original";
  const timezones = {
    ...allTimezones,
  };
  const { parseTimezone } = useTimezoneSelect({ labelStyle, timezones });

  const convertToLocalTime = (date, time, userTimeZone, prefilledTimeZone) => {
    const dateTimeStr = `${date}T${time}:00`;
    const prefilledTime = moment.tz(dateTimeStr, prefilledTimeZone);
    const localTime = prefilledTime.clone().tz(userTimeZone);
    return localTime.format("HH:mm");
  };

  const convertToLocalDate = (date, time, userTimeZone, prefilledTimeZone) => {
    const dateTimeStr = `${date}T${time}:00`;
    const prefilledTime = moment.tz(dateTimeStr, prefilledTimeZone);
    const localDate = prefilledTime.clone().tz(userTimeZone);
    return localDate.format("YYYY/MM/DD");
  };

  // Function to get user's local time zone
  const getUserTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };
  const userTimeZone = getUserTimeZone();

  const handleNewClassRequest = () => {
    setShowClassRequestModal(true);
  };

  const handleRequestPageClick = (event, value) => {
    setCurrentRequestPage(value - 1);
  };

  const handleRegisteredPageClick = (event, value) => {
    setCurrentRegisteredPage(value - 1);
  };

  const handleUpcomingPageClick = (event, value) => {
    setCurrentUpcomingPage(value - 1);
  };

  const handleEntriesPerPageChangeRequests = (event) => {
    const value = Number(event.target.value);
    setEntriesPerPageRequests(value);
    setCurrentRequestPage(0);
    Cookies.set(`entriesPerPageRequests_${user.gibbonPersonID}`, value, {
      expires: 7,
    });
  };

  const handleEntriesPerPageChangeRegistered = (event) => {
    const value = Number(event.target.value);
    setEntriesPerPageRegistered(value);
    setCurrentRegisteredPage(0);
    Cookies.set(`entriesPerPageRegistered_${user.gibbonPersonID}`, value, {
      expires: 7,
    });
  };

  const handleEntriesPerPageChangeUpcoming = (event) => {
    const value = Number(event.target.value);
    setEntriesPerPageUpcoming(value);
    setCurrentUpcomingPage(0);
    Cookies.set(`entriesPerPageUpcoming_${user.gibbonPersonID}`, value, {
      expires: 7,
    });
  };

  const toggleEditModal = (event, requestId, requestData) => {
    event.preventDefault();
    setEditRequestId(requestId);
    setEditRequestData(requestData);
    setShowEditModal(!showEditModal);
  };

  const toggleEditUpcomingModal = (event, requestId, requestData) => {
    event.preventDefault();
    setEditRequestId(requestId);
    setEditRequestData(requestData);
    setShowEditUpcomingClass(!showEditRegisteredClass);
  };

  const toggleEditRegisteredModal = (event, requestId, requestData) => {
    event.preventDefault();
    setEditRequestId(requestId);
    setEditRequestData(requestData);
    setShowEditRegisteredClass(!showEditRegisteredClass);
  };

  const handleRequestFilterChange = (e, column) => {
    const { value } = e.target;
    setRequestFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value,
    }));
    setCurrentRequestPage(0); // Reset to first page when filter changes
  };

  const handleRegisteredFilterChange = (e, column) => {
    const { value } = e.target;
    setRegisteredFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value,
    }));
    setCurrentRegisteredPage(0); // Reset to first page when filter changes
  };

  const handleUpcomingFilterChange = (e, column) => {
    const { value } = e.target;
    setUpcomingFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value,
    }));
    setCurrentUpcomingPage(0); // Reset to first page when filter changes
  };

  const filterRequestData = (data) => {
    return data.filter((item) => {
      const isMatching = Object.keys(requestFilters).every((key) => {
        let itemValue = "";
        if (key === "requestor") {
          itemValue = `${item.firstName || ""} ${item.surname || ""}`
            .trim()
            .toLowerCase();
        } else if (key === "lastModified") {
          itemValue = item.updated_at
            ? new Date(item.updated_at).toLocaleDateString().toLowerCase()
            : "";
        } else {
          itemValue = item[key] ? String(item[key]).toLowerCase() : "";
        }
        const filterValue = requestFilters[key].toLowerCase();
        const isIncluded = itemValue.includes(filterValue);
        return requestFilters[key] ? isIncluded : true;
      });
      return isMatching;
    });
  };

  const filterRegisteredData = (data) => {
    return data.filter((item) => {
      const isMatching = Object.keys(registeredFilters).every((key) => {
        let itemValue = "";
        if (key === "dateEnrolled") {
          itemValue = item?.dateEnrolled[0]
            ? new Date(item.dateEnrolled[0]).toLocaleDateString()
            : "No enrolled date";
        } else {
          itemValue = item
            ? item[key]
              ? String(item[key]).toLowerCase()
              : ""
            : "";
        }
        const filterValue = registeredFilters[key].toLowerCase();
        const isIncluded = itemValue.includes(filterValue);
        return registeredFilters[key] ? isIncluded : true;
      });
      return isMatching;
    });
  };

  const filterUpcomingData = (data) => {
    return data.filter((item) => {
      const isMatching = Object.keys(upcomingFilters).every((key) => {
        let itemValue = "";
        if (key === "startDate") {
          itemValue = item.startDate
            ? new Date(
                convertToLocalDate(
                  item.startDate,
                  item.startTime,
                  item.timeZone
                )
              ).toLocaleDateString()
            : "No date";
        } else if (key === "time") {
          itemValue = item.startTime
            ? `${convertToLocalTime(
                item.startDate,
                item.startTime,
                item.timeZone
              )}-${convertToLocalTime(
                item.endDate,
                item.endTime,
                item.timeZone
              )}`
            : "No time";
        } else if (key === "localTime") {
          itemValue = `${
            item.startDate
              ? new Date(
                  convertToLocalDate(
                    item.startDate,
                    item.startTime,
                    "America/Los_Angeles"
                  )
                ).toLocaleDateString()
              : "No date"
          } ${item.startTime}-${item.endTime}`;
        } else if (key === "classLocation") {
          itemValue = `${item.space.type}:${item.space.name}; ${item.space.comments}`;
        } else if (key === "timeZone") {
          itemValue = parseTimezone(item.timeZone).label.toLowerCase();
        } else {
          itemValue = item[key] ? String(item[key]).toLowerCase() : "";
        }
        const filterValue = upcomingFilters[key].toLowerCase();
        const isIncluded = itemValue.includes(filterValue);
        return upcomingFilters[key] ? isIncluded : true;
      });
      return isMatching;
    });
  };

  const filteredRequestData = filterRequestData(requestWidget);
  const requestOffset = currentRequestPage * entriesPerPageRequests;
  const currentRequestPageData =
    entriesPerPageRequests === -1
      ? filteredRequestData
      : filteredRequestData.slice(
          requestOffset,
          requestOffset + entriesPerPageRequests
        );

  // Update the page count calculation to avoid dividing by `-1`
  const requestPageCount =
    entriesPerPageRequests === -1
      ? 1
      : Math.ceil(filteredRequestData.length / entriesPerPageRequests);

  const filteredRegisteredData = filterRegisteredData(registeredClasses);
  const registeredOffset = currentRegisteredPage * entriesPerPageRegistered;
  const currentRegisteredClasses =
    entriesPerPageRegistered === -1
      ? filteredRegisteredData
      : filteredRegisteredData.slice(
          registeredOffset,
          registeredOffset + entriesPerPageRegistered
        );

  const registeredPageCount =
    entriesPerPageRegistered === -1
      ? 1
      : Math.ceil(filteredRegisteredData.length / entriesPerPageRegistered);

  const filteredUpcomingData = filterUpcomingData(upcomingClasses);
  const upcomingOffset = currentUpcomingPage * entriesPerPageUpcoming;
  const currentUpcomingClasses =
    entriesPerPageUpcoming === -1
      ? filteredUpcomingData
      : filteredUpcomingData.slice(
          upcomingOffset,
          upcomingOffset + entriesPerPageUpcoming
        );

  const upcomingPageCount =
    entriesPerPageUpcoming === -1
      ? 1
      : Math.ceil(filteredUpcomingData.length / entriesPerPageUpcoming);

  const handleMoreCommentsClick = (comments) => {
    setCurrentComments(comments);
    setShowCommentsModal(true);
  };

  return (
    <div className="user-home">
      <header className="header">
        <h1>{title}</h1>
      </header>
      <div className="content-userhome">
        <nav className="sidebar">
          <ul>
            <li
              onClick={() => handleNavigation("Dashboard")}
              className={title === "Dashboard" ? "active" : ""}
            >
              Dashboard
            </li>
            <li
              onClick={() =>
                handleNavigation("Navigation", GlobalConstant.GIBBON_URL)
              }
            >
              Navigation
            </li>
          </ul>
        </nav>
        <main className="main-content">
          {title === "Dashboard" && (
            <div>
              <div className="requests">
                <div className="requests-header">
                  <h2>Requests</h2>
                  {user?.gibbonRoleIDPrimary !== 1 && (
                    <button
                      className="new-class-request"
                      onClick={handleNewClassRequest}
                    >
                      New Class Request
                    </button>
                  )}
                </div>
                <div className="user-home-table-control">
                  {loadingRequests ? (
                    <p>Loading requests...</p>
                  ) : requestWidget.length === 0 ? (
                    <div className="no-request-dispaly">
                      <h3>You don’t have requests.</h3>
                    </div>
                  ) : (
                    <>
                      <table>
                        <thead>
                          <tr>
                            <th>
                              Last Modified
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={requestFilters.lastModified}
                                  onChange={(e) =>
                                    handleRequestFilterChange(e, "lastModified")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            <th>
                              Status
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={requestFilters.status}
                                  onChange={(e) =>
                                    handleRequestFilterChange(e, "status")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            {user?.gibbonRoleIDPrimary === 1 && (
                              <th>
                                Requestor
                                <div className="filter-dropdown">
                                  <input
                                    type="text"
                                    value={requestFilters.requestor}
                                    onChange={(e) =>
                                      handleRequestFilterChange(e, "requestor")
                                    }
                                    placeholder="Filter"
                                  />
                                </div>
                              </th>
                            )}
                            <th>
                              Type
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={requestFilters.type}
                                  onChange={(e) =>
                                    handleRequestFilterChange(e, "type")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            <th>
                              Course Name
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={requestFilters.courseName}
                                  onChange={(e) =>
                                    handleRequestFilterChange(e, "courseName")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            <th>
                              Class Name
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={requestFilters.className}
                                  onChange={(e) =>
                                    handleRequestFilterChange(e, "className")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            <th>
                              Purpose
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={requestFilters.purpose}
                                  onChange={(e) =>
                                    handleRequestFilterChange(e, "purpose")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            <th>
                              Comments
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={requestFilters.comments}
                                  onChange={(e) =>
                                    handleRequestFilterChange(e, "comments")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            {user?.gibbonRoleIDPrimary === 1 && (
                              <th>Actions</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {currentRequestPageData.map((request, index) => (
                            <tr key={index}>
                              <td>{request.updated_at}</td>
                              <td>{request.status}</td>
                              {user?.gibbonRoleIDPrimary === 1 && (
                                <td>{`${request.firstName} ${request.surname}`}</td>
                              )}
                              <td>{request.type}</td>
                              <td>{request.courseName}</td>
                              <td>
                                {request.courseClassName
                                  ? request.courseClassName
                                  : "no class yet"}
                              </td>
                              <td>{request.purpose}</td>
                              <td>
                                {request.comments ? (
                                  <>
                                    {`${request.comments.slice(0, 30)}... `}
                                    <a
                                      href="#"
                                      onClick={() =>
                                        handleMoreCommentsClick(
                                          request.comments
                                        )
                                      }
                                    >
                                      More
                                    </a>
                                  </>
                                ) : (
                                  "no comments yet"
                                )}
                              </td>
                              {user?.gibbonRoleIDPrimary === 1 && (
                                <td>
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      toggleEditModal(
                                        e,
                                        request.svtRequestID,
                                        request
                                      )
                                    }
                                    style={{
                                      pointerEvents:
                                        request.status === "solved"
                                          ? "none"
                                          : "auto",
                                      color:
                                        request.status === "solved"
                                          ? "gray"
                                          : "blue",
                                    }}
                                  >
                                    Edit
                                  </a>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                <div className="pagination-controls">
                  <label>
                    Show{" "}
                    <select
                      value={entriesPerPageRequests}
                      onChange={handleEntriesPerPageChangeRequests}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={-1}>All</option>
                    </select>{" "}
                    entries
                  </label>
                  <Pagination
                    sx={{
                      "& .MuiButtonBase-root": {
                        height: "3vh",
                        fontSize: "1.2vmin",
                      },
                    }}
                    count={requestPageCount}
                    siblingCount={0}
                    boundaryCount={3}
                    shape="rounded"
                    onChange={handleRequestPageClick}
                  />
                </div>
              </div>
              <div className="registered-classes">
                <div className="registered-classes-header">
                  <h2>Registered Classes</h2>
                  {user?.gibbonRoleIDPrimary === 1 && (
                    <button
                      className="create-new-class"
                      onClick={() => setShowCreateNewClass(true)}
                    >
                      Create New Class
                    </button>
                  )}
                </div>
                <div className="user-home-table-control">
                  {loadingRegisteredClasses ? (
                    <p>Loading registered classes...</p>
                  ) : registeredClasses.length === 0 ? (
                    <h3>You don’t have registered classes.</h3>
                  ) : (
                    <>
                      <table>
                        <thead>
                          <tr>
                            <th>
                              Date Enrolled
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={registeredFilters.dateEnrolled}
                                  onChange={(e) =>
                                    handleRegisteredFilterChange(
                                      e,
                                      "dateEnrolled"
                                    )
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            <th>
                              Date Unenrolled
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={registeredFilters.dateUnenrolled}
                                  onChange={(e) =>
                                    handleRegisteredFilterChange(
                                      e,
                                      "dateUnenrolled"
                                    )
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            <th>
                              Course Name
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={registeredFilters.courseName}
                                  onChange={(e) =>
                                    handleRegisteredFilterChange(
                                      e,
                                      "courseName"
                                    )
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            <th>
                              Class Name
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={registeredFilters.className}
                                  onChange={(e) =>
                                    handleRegisteredFilterChange(e, "className")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            {user?.gibbonRoleIDPrimary !== 2 && (
                              <th>
                                Teacher
                                <div className="filter-dropdown">
                                  <input
                                    type="text"
                                    value={registeredFilters.teacherNames}
                                    onChange={(e) =>
                                      handleRegisteredFilterChange(
                                        e,
                                        "teacherNames"
                                      )
                                    }
                                    placeholder="Filter"
                                  />
                                </div>
                              </th>
                            )}
                            {user?.gibbonRoleIDPrimary !== 3 && (
                              <th>
                                Students
                                <div className="filter-dropdown">
                                  <input
                                    type="text"
                                    value={registeredFilters.studentNames}
                                    onChange={(e) =>
                                      handleRegisteredFilterChange(
                                        e,
                                        "studentNames"
                                      )
                                    }
                                    placeholder="Filter"
                                  />
                                </div>
                              </th>
                            )}
                            {user?.gibbonRoleIDPrimary === 1 && (
                              <th>Actions</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {currentRegisteredClasses.map((classItem, index) => (
                            <tr key={index}>
                              <td>
                                {classItem?.dateEnrolled[0]
                                  ? classItem.dateEnrolled[0]
                                  : "No enrolled date"}
                              </td>
                              <td>
                                {classItem?.dateUnenrolled
                                  ? classItem.dateUnenrolled
                                  : "No Unenrolled date"}
                              </td>
                              <td>{classItem?.courseName}</td>
                              <td>{classItem?.className}</td>
                              {user?.gibbonRoleIDPrimary !== 2 && (
                                <td>
                                  {classItem?.teacherNames
                                    ? classItem.teacherNames
                                    : "No teacher yet"}
                                </td>
                              )}
                              {user?.gibbonRoleIDPrimary !== 3 && (
                                <td>
                                  {classItem?.studentNames
                                    ? classItem.studentNames
                                    : "No student yet"}
                                </td>
                              )}
                              {user?.gibbonRoleIDPrimary === 1 && (
                                <td>
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      toggleEditRegisteredModal(
                                        e,
                                        classItem.classID,
                                        {
                                          ...classItem,
                                          currentUserID: user.gibbonPersonID,
                                        }
                                      )
                                    }
                                    style={{
                                      pointerEvents:
                                        classItem.className.includes("Stopped")
                                          ? "none"
                                          : "auto",
                                      color: classItem.className.includes(
                                        "Stopped"
                                      )
                                        ? "gray"
                                        : "blue",
                                    }}
                                  >
                                    Edit
                                  </a>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                <div className="pagination-controls">
                  <label>
                    Show{" "}
                    <select
                      value={entriesPerPageRegistered}
                      onChange={handleEntriesPerPageChangeRegistered}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={-1}>All</option>
                    </select>{" "}
                    entries
                  </label>
                  <Pagination
                    sx={{
                      "& .MuiButtonBase-root": {
                        height: "3vh",
                        fontSize: "1.2vmin",
                      },
                    }}
                    count={registeredPageCount}
                    siblingCount={0}
                    boundaryCount={1}
                    shape="rounded"
                    onChange={handleRegisteredPageClick}
                  />
                </div>
              </div>
              <div className="upcoming-classes">
                <div className="upcoming-classes-header">
                  <h2>Upcoming Classes</h2>
                  <p>
                    * This is for Tutoring and Credit Course only, for SVT full time student classes, please go to "Navigation" for details.
                  </p>
                </div>
                <div className="user-home-table-control">
                  {loadingUpcomingClasses ? (
                    <p>Loading upcoming classes...</p>
                  ) : upcomingClasses.length === 0 ? (
                    <h3>You don’t have upcoming classes.</h3>
                  ) : (
                    <>
                      <table>
                        <thead>
                          <tr>
                            <th>
                              Date
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={upcomingFilters.startDate}
                                  onChange={(e) =>
                                    handleUpcomingFilterChange(e, "startDate")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            <th>
                              Time
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={upcomingFilters.time}
                                  onChange={(e) =>
                                    handleUpcomingFilterChange(e, "time")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            <th>
                              Time Zone
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={upcomingFilters.timeZone}
                                  onChange={(e) =>
                                    handleUpcomingFilterChange(e, "timeZone")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            <th>
                              Your Local Class Time
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={upcomingFilters.localTime}
                                  onChange={(e) =>
                                    handleUpcomingFilterChange(e, "localTime")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            <th>
                              Class Name
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={upcomingFilters.className}
                                  onChange={(e) =>
                                    handleUpcomingFilterChange(e, "className")
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            {user?.gibbonRoleIDPrimary !== 2 && (
                              <th>
                                Teacher
                                <div className="filter-dropdown">
                                  <input
                                    type="text"
                                    value={upcomingFilters.teacherNames}
                                    onChange={(e) =>
                                      handleUpcomingFilterChange(
                                        e,
                                        "teacherNames"
                                      )
                                    }
                                    placeholder="Filter"
                                  />
                                </div>
                              </th>
                            )}
                            {user?.gibbonRoleIDPrimary !== 3 && (
                              <th>
                                Students
                                <div className="filter-dropdown">
                                  <input
                                    type="text"
                                    value={upcomingFilters.studentNames}
                                    onChange={(e) =>
                                      handleUpcomingFilterChange(
                                        e,
                                        "studentNames"
                                      )
                                    }
                                    placeholder="Filter"
                                  />
                                </div>
                              </th>
                            )}
                            <th>
                              Class Location
                              <div className="filter-dropdown">
                                <input
                                  type="text"
                                  value={upcomingFilters.classLocation}
                                  onChange={(e) =>
                                    handleUpcomingFilterChange(
                                      e,
                                      "classLocation"
                                    )
                                  }
                                  placeholder="Filter"
                                />
                              </div>
                            </th>
                            {user?.gibbonRoleIDPrimary === 1 && (
                              <th>Actions</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {currentUpcomingClasses.map((classItem, index) => (
                            <tr key={index}>
                              <td>{classItem.startDate}</td>
                              <td>{`${classItem.startTime}-${classItem.endTime}`}</td>
                              <td>{parseTimezone(classItem.timeZone).label}</td>
                              <td>{`${
                                classItem.startDate
                                  ? convertToLocalDate(
                                      classItem.startDate,
                                      classItem.startTime,
                                      userTimeZone,
                                      classItem.timeZone
                                    )
                                  : "No date"
                              } ${convertToLocalTime(
                                classItem.startDate,
                                classItem.startTime,
                                userTimeZone,
                                classItem.timeZone
                              )}-${convertToLocalTime(
                                classItem.endDate,
                                classItem.endTime,
                                userTimeZone,
                                classItem.timeZone
                              )}`}</td>
                              <td>{classItem.className}</td>
                              {user?.gibbonRoleIDPrimary !== 2 && (
                                <td>
                                  {classItem.teacherNames
                                    ? classItem.teacherNames
                                    : "No teacher yet"}
                                </td>
                              )}
                              {user?.gibbonRoleIDPrimary !== 3 && (
                                <td>
                                  {classItem.studentNames
                                    ? classItem.studentNames
                                    : "No student yet"}
                                </td>
                              )}
                              <td>{`${classItem.space.type}:${classItem.space.name}; ${classItem.space.comments}`}</td>
                              {user?.gibbonRoleIDPrimary === 1 && (
                                <td>
                                  <a
                                    href="#"
                                    onClick={(e) =>
                                      toggleEditUpcomingModal(
                                        e,
                                        classItem.classID,
                                        {
                                          ...classItem,
                                          currentUserID: user.gibbonPersonID,
                                        }
                                      )
                                    }
                                  >
                                    Edit
                                  </a>
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                <div className="pagination-controls">
                  <label>
                    Show{" "}
                    <select
                      value={entriesPerPageUpcoming}
                      onChange={handleEntriesPerPageChangeUpcoming}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={-1}>All</option>
                    </select>{" "}
                    entries
                  </label>
                  <Pagination
                    sx={{
                      "& .MuiButtonBase-root": {
                        height: "3vh",
                        fontSize: "1.2vmin",
                      },
                    }}
                    count={upcomingPageCount}
                    siblingCount={0}
                    boundaryCount={3}
                    shape="rounded"
                    onChange={handleUpcomingPageClick}
                  />
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
      {showEditModal && (
        <UserEdit
          requestId={editRequestId}
          requestData={editRequestData}
          setShowModal={setShowEditModal}
          requestWidget={requestWidget}
          setRequestWidget={setRequestWidget}
          handleFetch={handleFetch}
          handleFetchRequest={fetchRequestWidget}
        />
      )}
      {showClassRequestModal && (
        <ClassRequestFormModal
          showModal={showClassRequestModal}
          setShowModal={setShowClassRequestModal}
          handleFetchRequest={fetchRequestWidget}
        />
      )}
      {showCreateNewClass && (
        <CreateEditClassModal
          setShowModal={setShowCreateNewClass}
          requestType={"new"}
          handleFetch={handleFetch}
        ></CreateEditClassModal>
      )}
      {showEditRegisteredClass && (
        <CreateEditClassModal
          setShowModal={setShowEditRegisteredClass}
          courseRequest={editRequestData}
          requestType={"registered"}
          handleFetch={handleFetch}
        ></CreateEditClassModal>
      )}
      {showEditUpcomingClass && (
        <CreateEditClassModal
          setShowModal={setShowEditUpcomingClass}
          courseRequest={editRequestData}
          requestType={"upcoming"}
          handleFetch={handleFetch}
        ></CreateEditClassModal>
      )}
      {showCommentsModal && (
        <CommentsModal
          isOpen={showCommentsModal}
          onRequestClose={() => setShowCommentsModal(false)}
          comments={currentComments}
        />
      )}
    </div>
  );
}

export default UserHome;
