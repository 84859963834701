/* eslint-disable jsx-a11y/img-redundant-alt */
import "./missionStatement.css";
import visionImg from "./images/vission.jpg";

const MissionStatement = () => {
  return (
    <div className="mission-statement-page">
      <div className="mission-statement-container">
        <div>
          <table>
            <tbody>
              <tr>
                <td style={{ width: "100%", padding: "15px" }}>
                  <span
                    style={{
                      width: "30vmin",
                      position: "relative",
                      float: "left",
                      clear: "right",
                    }}
                  >
                    <img
                      src={visionImg}
                      style={{ borderWidth: 0, maxWidth: "90%" }}
                      alt="Picture"
                    />
                  </span>
                  <div style={{ textAlign: "left", display: "block" }}>
                    <span
                      style={{
                        fontFamily: "Arial, sans-serif",
                        fontSize: "4vmin",
                        color: "#555555",
                        fontWeight: "bold",
                        textDecoration: "none",
                      }}
                    >
                      Mission Statement{" "}
                    </span>
                    <br />
                    <br />
                    <span
                      style={{
                        fontFamily: "Arial, sans-serif",
                        lineHeight: 1.6,
                        fontSize: "2.5vmin",
                        color: "#4b6296",
                        fontWeight: "plain",
                        textDecoration: "none",
                      }}
                    >
                      At Seattle VocTech, we strive to help each student develop
                      their unique abilities and interests. Our approach to
                      teaching and learning is holistic. In addition to
                      providing a standard core curriculum, we partner with
                      students to help them follow their curiosities. By helping
                      students integrate these interests into larger goals, we
                      promote intellectual development that supports real-world
                      goals. This two-pronged approach to learning cultivates
                      each student as an individual with unique hopes and
                      abilities.
                      <br />
                      <br />
                      We begin with the assumption that each of our students is
                      capable. We partner this assumption with a program
                      characterized by structured flexibility. While hopes and
                      dreams play an important part of our approach, we make
                      them practical. We work with students to help them
                      discover goals and refine them to make them reachable.
                      Once students identify goals, we develop a systematic
                      program for working toward those goals. Often this
                      increases appreciation of core learning as part of what is
                      needed to achieve goals. This approach enables us to help
                      students pair standards and expectations with the pursuit
                      of their own goals and interests.
                      <br />
                      <br />
                      A key component of our teaching strategy is small class
                      size. This enables teachers to identify individual
                      learning needs and respond to them. As a result, we design
                      coursework and learning pace to levels appropriate to each
                      student. For students with a strong academic core, this
                      means they are able to move through the standard
                      curriculum quickly. For students with unique needs, we
                      provide instruction to develop their capabilities.
                      <br />
                      <br />
                      To help students refine their interests and develop
                      capabilities to pursue those interests successfully, we
                      build a passion project into each student's curriculum
                      coursework. The passion project provides students with
                      opportunities to develop a learning path unique to their
                      interests. We do this by providing a structure to
                      incorporate approved extracurricular activities into the
                      structure of their learning.
                      <br />
                      <br />
                      Our curriculum aims to help students understand that they
                      are members of a larger community.
                      <br />
                      <br />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MissionStatement;
