import React, { useState, useEffect } from "react";
import "./tutorialandCreditCourse.css";
import axiosClient from "../axiosClient";

const TutorialandCreditCourse = () => {
  const [user, setUser] = useState(null);
  const [ifGrade, setIfGrade] = useState(false);
  const [formData, setFormData] = useState({
    courseName: "",
    courseID: "",
    departmentName: "",
    classPurpose: "tutorial",
    studentFirstName: "",
    studentLastName: "",
    gender: "",
    school: "",
    grade: "",
    email: "",
    phoneNumber: "",
  });
  const [courseNames, setCourseNames] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const accessToken = localStorage.getItem("ACCESS_TOKEN");
      if (accessToken) {
        try {
          const response = await axiosClient.get(`/user`);
          setUser(response.data);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const fetchCourseNames = async () => {
      try {
        const response = await axiosClient.get(`/allcourses`);
        const sortedCourseNames = response.data.data.sort(
          (a, b) => a.sequenceNumber - b.sequenceNumber
        );
        setCourseNames(sortedCourseNames);
        if (process.env.REACT_APP_ENV.trim() != "production") console.log(sortedCourseNames); // debug only
      } catch (error) {
        console.error("Failed to fetch course names:", error);
      }
    };

    fetchCourseNames();
  }, []);

  /**
   * Safely parse a JSON string and return the parsed object or an empty object.
   * Note: If JSON is invalid or empty, it will return an empty object.
   */
  const safeJsonParse = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return {};
    }
  };

  useEffect(() => {
    if (user) {
      // Parse the JSON fields and extract the grade
      const userFields = safeJsonParse(user.fields);
      const userGrade = userFields.grade || "";
      setIfGrade(userGrade === "" ? false : true);
      setFormData({
        ...formData,
        studentFirstName: user.firstName,
        studentLastName: user.surname,
        gender: user.gender,
        email: user.email,
        grade: userGrade,
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newErrors = { ...errors };

    switch (name) {
      case "departmentName":
        newErrors.departmentName = value.trim()
          ? ""
          : "Learning area is required";
        break;
      case "courseName":
        newErrors.courseName = value.trim() ? "" : "Course name is required";
        break;
      case "classPurpose":
        newErrors.classPurpose = value.trim() ? "" : "classPurpose is required";
        break;
      case "studentFirstName":
        newErrors.studentFirstName = value.trim()
          ? ""
          : "First name is required";
        break;
      case "studentLastName":
        newErrors.studentLastName = value.trim() ? "" : "Last name is required";
        break;
      case "grade":
        newErrors.grade = value.trim() ? "" : "Grade is required";
        break;
      case "email":
        newErrors.email = value.trim()
          ? /^\S+@\S+\.\S+$/.test(value)
            ? ""
            : "Invalid email address"
          : "Email is required";
        break;
      default:
        break;
    }

    if (name === "courseName") {
      const selectedCourse = courseNames.find(
        (course) => course.courseID === parseInt(value)
      );
      setFormData({
        ...formData,
        courseName: selectedCourse ? selectedCourse.courseName : "",
        courseID: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = Object.values(errors).every((error) => error === "");

    if (isValid) {
      try {
        await axiosClient.post(`/request`, formData);
        setSubmitted(true);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      console.log("Form has errors, cannot submit");
    }
  };

  const handleReset = () => {
    setFormData({
      courseName: "",
      courseID: "",
      departmentName: "",
      classPurpose: "tutorial",
      studentFirstName: user ? user.firstName : "",
      studentLastName: user ? user.surname : "",
      gender: user ? user.gender : "",
      school: "",
      grade: "",
      email: user ? user.email : "",
      phoneNumber: "",
    });
    setErrors({});
  };

  return (
    <div className="tutorial-page">
      <div className="tutorial-container">
        <div className="tutorial-paragraph">
          <span>
            <br />
            <br />
            Seattle VocTech School provides private or group tutoring and instruction on courses that qualify for school credit. Fill in the request form below to sign up for classes your student wishes to take and we will evaluate and assign the right teacher for your student.
            <br />
            (Disclaimer: This form is currently for sign up to private 1:1 classes only, if students would like to sign up for group (1 on multiple) classes please <a href="https://seattlevt.org/contact-us">contact us</a> or email to <a href="mailto: contact@seattlevt.org">contact@seattlevt.org</a>.)
          </span>
        </div>
        <div className="form-header">
          <h3>Class Request Form</h3>
        </div>
        {!submitted ? (
          <div className="tutorial-form-container">
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-row-header">Learning Area *</div>
                <div className="form-row-content">
                  <select
                    id="departmentName"
                    name="departmentName"
                    value={formData.departmentName}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled={true}>
                      Select a Learning Area
                    </option>
                    {[
                      ...new Set(
                        courseNames.map((course) => course.departmentName)
                      ),
                    ]
                      // .sort()
                      .map((department) => (
                        <option key={department} value={department}>
                          {department}
                        </option>
                      ))}
                  </select>
                  {errors.departmentName && (
                    <span className="tutorial-form-error">
                      {errors.departmentName}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-row-header">Course Name *</div>
                <div className="form-row-content">
                  <select
                    id="courseName"
                    name="courseName"
                    value={formData.courseID}
                    onChange={handleChange}
                    required
                    disabled={!formData.departmentName}
                  >
                    <option value="" disabled={true}>
                      Select a Course
                    </option>
                    {courseNames
                      .filter(
                        (course) =>
                          course.departmentName === formData.departmentName
                      )
                      .map((course) => (
                        <option key={course.courseID} value={course.courseID}>
                          {course.courseName}
                        </option>
                      ))}
                  </select>
                  {errors.courseName && (
                    <span className="tutorial-form-error">
                      {errors.courseName}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-row-header">Class Purpose *</div>
                <div className="form-row-content radio-group">
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="tutorial"
                      name="classPurpose"
                      value="tutorial"
                      checked={formData.classPurpose === "tutorial"}
                      onChange={handleChange}
                    />
                    <label htmlFor="tutorial">Tutoring</label>
                  </div>
                  <div className="radio-item">
                    <input
                      type="radio"
                      id="private credit"
                      name="classPurpose"
                      value="private credit"
                      checked={formData.classPurpose === "private credit"}
                      onChange={handleChange}
                    />
                    <label htmlFor="private credit">
                      Credit Course
                    </label>
                  </div>
                </div>
                {errors.classPurpose && (
                  <span className="tutorial-form-error">
                    {errors.classPurpose}
                  </span>
                )}
              </div>

              <div className="form-row">
                <div className="form-row-header">Student First Name *</div>
                <div className="form-row-content">
                  <input
                    type="text"
                    id="studentFirstName"
                    name="studentFirstName"
                    value={formData.studentFirstName}
                    onChange={handleChange}
                    placeholder="Please enter Student first Name"
                    autoFocus
                    required
                    disabled={user ? true : false}
                  />
                  {errors.studentFirstName && (
                    <span className="tutorial-form-error">
                      {errors.studentFirstName}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-row-header">Student Last Name *</div>
                <div className="form-row-content">
                  <input
                    type="text"
                    id="studentLastName"
                    name="studentLastName"
                    value={formData.studentLastName}
                    onChange={handleChange}
                    placeholder="Please enter Student Last Name"
                    required
                    disabled={user ? true : false}
                  />
                  {errors.studentLastName && (
                    <span className="tutorial-form-error">
                      {errors.studentLastName}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-row-header">Gender </div>
                <div className="form-row-content">
                  <select
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                  >
                    <option value="">Select Gender</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  {errors.gender && (
                    <span className="tutorial-form-error">{errors.gender}</span>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-row-header">School </div>
                <div className="form-row-content">
                  <input
                    type="text"
                    id="school"
                    name="school"
                    value={formData.school}
                    onChange={handleChange}
                    placeholder="Please enter your School"
                  />
                  {errors.school && (
                    <span className="tutorial-form-error">{errors.school}</span>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-row-header">Grade *</div>
                <div className="form-row-content">
                  <input
                    type="text"
                    id="grade"
                    name="grade"
                    value={formData.grade}
                    onChange={handleChange}
                    placeholder="Please enter your Grade"
                    required
                    disabled={ifGrade ? true : false}
                  />
                  {errors.grade && (
                    <span className="tutorial-form-error">{errors.grade}</span>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-row-header">Email Address *</div>
                <div className="form-row-content">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Please enter your Email"
                    required
                    disabled={user ? true : false}
                  />
                  {errors.email && (
                    <span className="tutorial-form-error">{errors.email}</span>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-row-header">Cell Phone </div>
                <div className="form-row-content">
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    className="large-input"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    placeholder="Please enter your Cell Phone"
                  />
                  {errors.phoneNumber && (
                    <span className="tutorial-form-error">
                      {errors.phoneNumber}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-actions">
                <input
                  type="submit"
                  value="Submit"
                  disabled={
                    !formData.departmentName ||
                    !formData.courseID ||
                    !formData.classPurpose ||
                    !formData.studentFirstName ||
                    !formData.studentLastName ||
                    !formData.grade ||
                    !formData.email ||
                    Object.values(errors).some((error) => error !== "")
                  }
                />
                <button type="button" onClick={handleReset}>
                  Reset
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div
            className="thank-you-message"
            style={{ height: "570px", textAlign: "center" }}
          >
            <br />
            <h4>Thank you for your message!</h4>
            <br />
          </div>
        )}
        <br />
        <br />
      </div>
    </div>
  );
};

export default TutorialandCreditCourse;
