import React, { useState } from "react";
import "./addComments.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import axiosClient from "../../axiosClient";
function AddComments({
  setShowCommentsModal,
  setRequest,
  request,
  fetchRequest,
}) {
  const [comment, setComment] = useState("");

  const handleSaveComment = async () => {
    // Implement the save comment functionality here
    //console.log(comment);

    try {
      const response = await axiosClient.post(`/addRequestComments`, {
        requestID: request.svtRequestID,
        comments: comment, // Send the updated status
      });
      if (response.status === 200) {
        await fetchRequest();
        alert("Comment updated successfully");

        // Optionally, you can refresh the request data or perform other actions here
      } else {
        alert("Failed to update Comment");
      }
    } catch (error) {
      console.error("Error updating request:", error);
      // alert("Error updating request");
    }
    setShowCommentsModal(false);
  };

  return (
    <div className="add-comments-modal">
      <div className="add-comments-container">
        <button
          className="close-add-comments-button"
          onClick={() => setShowCommentsModal(false)}
        >
          <ClearOutlinedIcon />
        </button>
        <h2>Update Comment</h2>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Enter your comment here"
        ></textarea>
        <button className="save-comment-button" onClick={handleSaveComment}>
          Save Comment
        </button>
      </div>
    </div>
  );
}

export default AddComments;
