import "./collegeCase.css";
import zane from "./thumbnail/case-bbhs-zane.jpg";
import katherine from "./thumbnail/case-bbhs-katherine.jpg";
import suray from "./thumbnail/case-bbhs-suray.jpg";
import sahana from "./thumbnail/case-bbhs-sahana.jpg";
import michael from "./thumbnail/case-bbhs-michael.jpg";

const CollegeCase = () => {
  return (
    <div className="college-case-page">
      <div className="college-case-container">
        <h2 className="college-case-header" align="center">
          College Case
        </h2>

        <ol className="college-case-content" type="1">
          <li>
            Zane Zook, Stanford University
            <div className="wsite-multicol">
              <table className="wsite-multicol-table">
                <tbody>
                  <tr>
                    <td className="description-container">
                      <div className="description">
                        Zane Zook, one of our first students to attend grade
                        school thru to High School created a fabulous legacy for
                        himself. During High School he kept his grades high
                        (3.97 gpa) and created a focused record of activity by
                        founding both the WASABI Robotics team and the Interlake
                        Guitar Club. As leader of the Guitar Club he
                        orchestrated their playing in front of audiences of
                        greater than 1,000 four times and as the President of
                        WASABI Robotics, he built the robot that won a World
                        Championship! Eventually, he achieved admissions to
                        Stanford, Berkeley, Yale, Dartmouth, Pomona, Davidson
                        and Swarthmore, deciding in the end to attend Stanford
                        University for Mechanical Engineering. (Note: He has
                        recently accepted an offer to study for his PhD in
                        Mechanical Engineering at Rice University.)
                      </div>
                    </td>
                    <td align="center" className="img-container">
                      <img src={zane} height="300" alt="zane" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li>
            Katherine Chen, Duke University
            <div className="wsite-multicol">
              <table className="wsite-multicol-table">
                <tbody>
                  <tr>
                    <td align="center" className="img-container">
                      <img src={katherine} height="300" alt="katherine" />
                    </td>
                    <td className="description-container">
                      <div className="description">
                        Katherine Chen was one of our first students transfering
                        into our program from the Bellevue School District’s
                        Highly Gifted High School Program. After two years at
                        BBP she graduated with the singular distinction of being
                        named the Washington State AP State Scholar (having
                        taken more AP exams than any other female in the state).{" "}
                        <br />
                        <br />
                        Her dream was to attend Yale and she worked hard to
                        achieve that dream. In the end, she was waitlisted by
                        Yale but achieved admission to UC-Berkeley, Dartmouth,
                        Cornell, Brown and Duke. Her second choice, Duke, made a
                        competitive offer (nearly $20,000 in grants) and she is
                        happily attending, studying for a dual-major in Computer
                        Science and Psychology.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li>
            Suray Cidambi, University of Iowa
            <div className="wsite-multicol">
              <table className="wsite-multicol-table">
                <tbody>
                  <tr>
                    <td className="description-container">
                      <div className="description">
                        Surya Cidambi attended Redmond Jr. High and High School.
                        He attended our school for supplemental help in Biology,
                        Reading, Mathematics, Physics, SAT Prep and college
                        application help and was a four year member of WASABI
                        Robotics. Surya did fine in High School (3.87 gpa) until
                        Junior year when he developed a Video Gaming Addiction
                        and managed to get Fs in three classes and Cs in the
                        other four. After such a poor performance his ability to
                        gain admittance to any 4 years schools was in doubt.
                        After concentrated effort during his Junior year summer
                        and on into Senior year, he was able to get offers from
                        Washington State University, University of Illinois:
                        Urbana-Champaign, and, his eventual choice, the
                        University of Iowa, where he is happily attending
                        school.
                      </div>
                    </td>
                    <td align="center" className="img-container">
                      <img src={suray} height="300" alt="suray" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li>
            Sahana Cidambi, University of Washington
            <div className="wsite-multicol">
              <table className="wsite-multicol-table">
                <tbody>
                  <tr>
                    <td align="center" className="img-container">
                      <img src={sahana} height="300" alt="sahana" />
                    </td>
                    <td className="description-container">
                      <div className="description">
                        Sahana Cidambi was a long-term member of WASABI
                        Robotics, including being on the World Championship
                        winning squad. She was also a tutoring student for
                        multiple classes and College Admissions help throughout
                        her high school career. Finally, during high school she
                        also helped teach robotics at the University of
                        Washington. <br />
                        <br />
                        As a senior, she worked long and hard with us on some
                        memorable college essays, including one conjecturing
                        about an ancient Indian rap-battle, that helped her get
                        into Berkeley, Olin, Scripps and Davidson before finally
                        settling on University of Washington.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li>
            Michael Kwong, University of Washington
            <div className="wsite-multicol">
              <table className="wsite-multicol-table">
                <tbody>
                  <tr>
                    <td className="description-container">
                      <div className="description">
                        Michael Kwong was a student on and off throughout high
                        school. After attending Bellevue College for two years,
                        and being rejected by every four-year college he applied
                        to, he returned for help and advice with his college
                        applications. <br />
                        <br />
                        Working with us he produced a Brag Sheet (resume) and
                        essays that better highlighted his unique history and
                        abilities. He was overjoyed to achieve his lifelong
                        dream of attending the University of Washington.
                      </div>
                    </td>
                    <td align="center" className="img-container">
                      <img src={michael} height="300" alt="michael" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default CollegeCase;
